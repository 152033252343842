<template>
	<div>
		<header class="flex items-center m-2 pb-6 border-b-2 border-phGray">
			<h1 class="mr-5 font-bold text-2xl">
				Advertoria
			</h1>
			<PhButton
				class="font-bold"
				@click="onAddNewAdvertoria"
			>
				Add new
			</PhButton>
			<PhModal
				v-model="isToggle"
			>
				<div class="relative h-full">
					<div
						class="bg-phMainBackground rounded absolute left-1/2 top-1/4 w-full p-5"
						style="max-width: 600px; height: 400px; transform: translateX(-50%);"
					>
						<h2 class="text-xl font-bold text-phAdminGrey border-b-2 pb-2 border-phAdminGrey">
							Add Banner
						</h2>
						<div class="flex p-5 mt-5 h-4/5">
							<div
								class="flex flex-col items-center justify-evenly w-1/2 h-full border border-phBlack bg-phBlack mr-3 rounded-2xl cursor-pointer"
								@click="$router.push('/advertoria/large-leader-board')"
							>
								<img
									src="@/assets/leader.svg"
									width="150"
								>
								<p class="font-bold text-phAdminGrey">
									Large Leaderboard
								</p>
							</div>
							<div
								class="flex flex-col items-center justify-evenly w-1/2 h-full border border-phBlack bg-phBlack rounded-2xl cursor-pointer"
								@click="$router.push('/advertoria/rectangle')"
							>
								<img
									src="@/assets/rectangle.svg"
									width="150"
								>
								<p class="font-bold text-phAdminGrey">
									Rectangle
								</p>
							</div>
						</div>
					</div>
				</div>
			</PhModal>
		</header>
		<main>
			<PhTable
				:data="tableData.data"
				:headers="tableData.headers"
				:offset-list="tableData.countList"
				:status-list="tableData.statusList"
				:offset="tableData.offset"
				class="advertoria-table"
				@onRowClick="onEditAdvertoria"
			>
				<template #content-image="{ item }">
					<div class="max-w-xs">
						<img
							:src="item"
							class="w-full"
						>
					</div>
				</template>
				<template #content-advertoriaId="{ item }">
					<PhButton @click="onDeleteRow(item)">
						Delete
					</PhButton>
				</template>
			</PhTable>
		</main>
		<PhModal
			v-model="isEditAdvertoria"
			class="flex items-center justify-center"
		>
			<div class="relative z-4500 bg-phMainBackground max-w-md rounded w-full p-5 ">
				<div
					class=""
				>
					<form
						class="flex-1 mt-10"
						@submit.prevent="onSubmit"
					>
						<h3 class="font-bold text-phAdminGrey mb-2">
							Name
						</h3>
						<PhInput
							v-model="values.name"
							class="w-full mb-5"
							placeholder="Please enter advertoria name"
							:error="errors.name"
							@validate="validate('name')"
						/>
						<h3 class="font-bold text-phAdminGrey">
							Rectangle
						</h3>
						<p class="text-phGray mb-4">
							This image will display all device
						</p>
						<div
							v-if="values.desktopImage"
							class="w-full mb-5 text-center"
						>
							<img
								:src="values.desktopImage"
								style="object-fit: content;"
							>
						</div>
						<div
							v-else
							class="w-full mb-5 text-center border-4 border-phGrey border-dotted rounded-md p-8 text-phGray"
						>
							Recommend size 300x200
						</div>
						<p
							v-if="!values.desktopImage && errors.desktopImage"
							class="text-phRed mt-2"
						>
							{{ errors.desktopImage }}
						</p>
						<input
							ref="desktopImageInput"
							type="file"
							class="hidden"
							accept="image/*"
							@change="handlerDesktopImage"
							@validate="validate('desktopImageFile')"
						>
						<PhButton
							class="mb-5 font-bold"
							type="button"
							@click="onUploadDesktopImageClick"
						>
							Browse
						</PhButton>
						<h3 class="font-bold text-phAdminGrey mb-5">
							Position
						</h3>
						<PhSelect
							v-model="values.position"
							:items="positionList"
							:error="errors.position"
							label="label"
							value="value"
							placeholder="Plase select page"
							class="w-full mb-5"
							@validate="validate('position')"
						/>
						<h3 class="font-bold text-phAdminGrey mb-5">
							Page
						</h3>
						<PhSelect
							v-model="values.page"
							:items="pageList"
							:error="errors.page"
							label="label"
							value="value"
							placeholder="Plase select page"
							class="w-full"
							@validate="validate('page')"
						/>
						<PhButton
							class="mt-10 w-full p-3 bg-phAdminGrey font-bold hover:bg-phGray"
							type="reset"
							@click="onCancelUpdate"
						>
							Cancel
						</PhButton>
						<PhButton
							class="mt-5 w-full p-3 font-bold"
							type="submit"
						>
							Update
						</PhButton>
					</form>
				</div>
			</div>
		</PhModal>
	</div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import PhButton from '@/components/global/PhButton.vue'
import PhTable from '@/components/global/PhTable.vue'
import PhModal from '@/components/global/PhModal.vue'
import PhSelect from '@/components/global/PhSelect.vue'
import { getAdvertorias, uploadAdvertiseImage, updateAdvertoria, deleteAdvertoria } from '@/api/admin/advertoria.api'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'

export default defineComponent({
	components: {
		PhButton,
		PhTable,
		PhModal,
		PhSelect
	},
	setup () {
		const isToggle = ref(false)
		const isEditAdvertoria = ref(false)
		const tableData = reactive({
			data: [],
			headers: [
				{
					label: 'Preview',
					key: 'image'
				},
				{
					label: 'Name',
					key: 'name'
				},
				{
					label: 'Position',
					key: 'position'
				},
				{
					label: 'Last update',
					key: 'updateDate'
				},
				{
					label: 'Delete',
					key: 'advertoriaId'
				}
			],
			countList: [
				{
					label: '10',
					value: 10
				},
				{
					label: '25',
					value: 25
				},
				{
					label: '50',
					value: 50
				}
			],
			statusList: [
				{
					label: 'All Status',
					status: 0
				},
				{
					label: 'Actor',
					status: 1
				},
				{
					label: 'customer',
					status: 2
				}
			],
			offset: 10
		})

		const { validate, validateAll, errors, values } = useValidation(
			{
				name: yup.string().required('Please enter Advertoria Name'),
				desktopImage: yup.string().required('Please select image'),
				position: yup.string().required('Please select position'),
				page: yup.string().required('Please select page')
			},
			{
				id: null,
				desktopImageFile: null,
				name: '',
				desktopImage: '',
				ratioW: '',
				ratioH: '',
				position: '',
				page: '',
				isLoading: false
			}
		)
		const desktopImageInput = ref(null)
		const pageList = ref([
			{
				text: 'Homepage',
				value: 'Homepage'
			},
			{
				text: 'Videopage',
				value: 'Videopage'
			}
		])
		const positionList = ref([
			{
				text: 'Desktop',
				value: 'Desktop'
			},
			{
				text: 'Mobile',
				value: 'Mobile'
			}
		])

		const handlerDesktopImage = (event) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				values.value.desktopImage = e.target.result
			}
			values.value.desktopImageFile = files
			reader.readAsDataURL(files)
			event.target.value = ''
		}
		const onSubmit = async() => {
			const isValid = await validateAll()
			if (!isValid) return
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					try {
						let image = values.value.desktopImage
						if (values.value.desktopImageFile) {
							const { status, message: { data } } = await uploadAdvertiseImage({file: values.value.desktopImageFile})
							image = data.Location
						}
						const {id, name, position, page } = values.value
						const params = {
							id: values.value.advertoriaId,
							name,
							image,
							ratioW: 300,
							ratioH: 200,
							position: 'Desktop',
							page
						}
						const response =  await updateAdvertoria(params)
						if (response.status === 1000) {
							await getInitailAdvertoria()
							Swal.fire({
								title:'Upload Success !!',
								icon: 'success',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#BA3B1A'
							})
							isEditAdvertoria.value = false
							values.value = {
								id: null,
								desktopImageFile: null,
								name: '',
								desktopImage: '',
								ratioW: '',
								ratioH: '',
								position: '',
								page: '',
								isLoading: false
							}
						} else {
							Swal.fire({
								title:'Upload fail !!',
								icon: 'error',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#BA3B1A'
							})
						}
					} catch (error) {
						console.error(error)
					}
				}
			})
		}

		const onUploadDesktopImageClick = () => {
			desktopImageInput.value.click()
		}
		onMounted(async() => {
			await getInitailAdvertoria()
		})
		const getInitailAdvertoria = async() => {
			const { status, message: { data } } = await getAdvertorias({page: 1, size: 1000})
			if (status === 1000) {
				tableData.data = data.advertises.reverse().map(e => {
					return {
						...e,
						updateDate: dayjs(e.update_at).format('DD/MM/YYYY'),
						advertoriaId: e['_id']
					}
				})
			} else {
				Swal.fire({
					title:'Get advertoria Failed !!',
					icon: 'error',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#BA3B1A'
				})
			}
		}
		const onEditAdvertoria = (item) => {
			values.value = {
				...item,
				desktopImage: item.image
			}
			isEditAdvertoria.value = true
		}
		const onCancelUpdate = () => {
			errors.value = {
				name: '',
				desktopImage: '',
				position: '',
				page: ''
			}
			isEditAdvertoria.value = false
		}
		const onDeleteRow = (advertoriaId) => {
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					try {
						await deleteAdvertoria({ id: advertoriaId }).then((response => {
							if (response.status === 1000) {
								const tableList = tableData.data
								tableData.data = tableList.filter(item => item._id !== response.message.data)
							} else {
								Swal.fire({
									title: response.message.error,
									icon: 'error',
									confirmButtonText: 'Ok',
									confirmButtonColor: '#BA3B1A'
								})
							}
						}))
					} catch (error) {
						console.error(error)
					}
				}
			})
		}
		const onAddNewAdvertoria = () => {
			isToggle.value = !isToggle.value
		}
		return {
			positionList,
			pageList,
			onCancelUpdate,
			onEditAdvertoria,
			onAddNewAdvertoria,
			tableData,
			isToggle,
			onDeleteRow,
			isEditAdvertoria,
			onSubmit,
			desktopImageInput,
			onUploadDesktopImageClick,
			handlerDesktopImage,
			getInitailAdvertoria,
			values,
			errors,
			validate,
			validateAll
		}
	}

})
</script>
<style lang="scss" scoped>
:deep(.advertoria-table) {
	.header-image {
		width: 350px;
	}
	.content-image {
		width: 350px;
	}
}
</style>

<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
