import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

export type TCategoryParams = {
    page: number
}

export type FFile = {
    file: File,
    onUploadProgress?: () => unknown
}

export const getAllCategories = (params: TCategoryParams): Promise<AxiosResponse> =>
	axiosInstance.get('/categories', {
		params
	}).then(response => response.data)

export interface ITagParams {
	id: string,
	name: string,
	image?: string
}

export const editCategoryById = (params: TCategoryParams): Promise<AxiosResponse<ITagParams>> =>
	axiosInstance.put('/categories', params).then(response => response.data)

export const addCategory = (params: TCategoryParams): Promise<AxiosResponse<ITagParams>> =>
	axiosInstance.post('/categories', params).then(response => response.data)

export const deleteCategoryById = (categoryId: string): Promise<AxiosResponse> =>
	axiosInstance.delete('/categories', {
		data: { id: categoryId }
	}).then(response => response.data)


export const addCategoryImage = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/categories', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}
