<template>
	<div class=" bg-phBlack w-full h-full pt-6 px-6">
		<div>
			<h1 class="py-4 mb-8 font-bold text-xl border-b-2 border-phAdminGrey">
				Add new
			</h1>
			<form
				class="max-w-sm"
				@submit.prevent="onSubmitForm"
			>
				<div class="mb-4">
					<h2 class="font-bold mb-1">
						Display Name
					</h2>
					<PhInput
						v-model="formDetail.displayName"
						class="w-full mt-3"
						:error="errors.displayName"
						@validate="validate('displayName')"
					/>
				</div>
				<div class="mb-4">
					<h2 class="font-bold mb-1">
						Username
					</h2>
					<PhInput
						v-model="formDetail.username"
						class="w-full mt-3"
						:error="errors.username"
						@validate="validate('username')"
					/>
				</div>
				<div class="mb-4">
					<h2 class="font-bold mb-1">
						Password
					</h2>
					<PhInput
						v-model="formDetail.password"
						class="w-full mt-3"
						:error="errors.password"
						type="password"
						@validate="validate('password')"
					/>
				</div>
				<div class="mb-4">
					<h2 class="font-bold mb-1">
						Gender
					</h2>
					<div>
						<label
							v-for="(gender, i) in memberDetail.genderTypeList"
							:key="`gender-${i}`"
							class="inline-flex items-center p-3"
						>
							<input
								v-model="formDetail.gender"
								type="radio"
								class="form-checkbox h-5 w-5 text-pink-600"
								:value="gender.label"
								:error="errors.gender"
								@change="validate('gender')"
							><span class="ml-2 text-phAdminGrey">{{ gender.label }}</span>
						</label>
						<p
							v-if="errors.gender"
							:class="`${errors.gender ? 'text-phRed':''}`"
						>
							{{ errors.gender }}
						</p>
					</div>
				</div>
				<!-- <div> -->
				<!-- <h2>Thumbnail</h2> -->
				<!-- <div class="flex mt-4 items-center"> -->
				<!-- <div class="image-container"> -->
				<!-- <img :src="formDetail.image ? formDetail.image:require('@/assets/icon/user-inactive.svg')"> -->
				<!-- </div> -->
				<!-- <div class="image-input ml-6"> -->
				<!-- <p>Upload thumbnail image 10mb Maximum</p> -->
				<!-- <input -->
				<!-- ref="memberImageInput" -->
				<!-- type="file" -->
				<!-- class="hidden" -->
				<!-- accept="image/*" -->
				<!-- @change="handlerMemberImage" -->
				<!-- @validate="validate('member')" -->
				<!-- > -->
				<!-- <button -->
				<!-- class="mt-2 rounded text-center outline-none" -->
				<!-- @click.prevent="onUploadMemberImageClick" -->
				<!-- > -->
				<!-- Browse -->
				<!-- </button> -->
				<!-- </div> -->
				<!-- </div> -->
				<!-- <p -->
				<!-- v-if="!formDetail.image && errors.image" -->
				<!-- style="color: #E44918" -->
				<!-- > -->
				<!-- {{ errors.image }} -->
				<!-- </p> -->
				<!-- </div> -->
				<PhButton
					class="px-8"
					type="submit"
				>
					Upload
				</PhButton>
			</form>
		</div>
	</div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, watch } from 'vue'
import PhInput from '@/components/global/PhInput.vue'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import Swal from 'sweetalert2'
import { createMember, getMemberById, updateMember } from '@/api/admin/member.api'
import { useRoute } from 'vue-router'
export default defineComponent({
	name: 'UserAdd',
	components: {
		PhInput
	},
	setup() {
		const memberImageInput = ref(null)
		const route = useRoute()
		const { validate, validateAll, errors, values: formDetail } = useValidation(
			{
				displayName: yup.string().required('Please enter display name'),
				username: yup.string().required('Please enter user'),
				password: yup.string().required('Please enter password'),
				gender: yup.string().required('Please select gender')
				// image: yup.string().required('Please select image')
			},
			{
				displayName: '',
				username: '',
				password: '',
				// image: '',
				gender: ''
			}
		)
		onMounted(async() => {
			if (route.query.updateMemberId) {
				const { message: { data: userDetail }} = await getMemberById(route.query.updateMemberId)
				formDetail.value.displayName = userDetail.displayName
				formDetail.value.username = userDetail.username
				formDetail.value.gender = userDetail.gender
			}
		})

		watch(
			() => route.query,
			() => {
				formDetail.value.displayName = '',
				formDetail.value.username = '',
				formDetail.value.password = '',
				formDetail.value.gender = ''
			}
		)

		const memberDetail = reactive({
			genderTypeList: [
				{
					id: 0,
					label: 'Male'
				},
				{
					id: 1,
					label: 'Female'
				}
			]
		})
		const handlerMemberImage = (event) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				formDetail.value.image = e.target.result
			}

			// uploadPayload.image = files

			reader.readAsDataURL(files)
			event.target.value = ''
		}

		const onUploadMemberImageClick = () => {
			memberImageInput.value.click()
		}

		const onSubmitForm = async() => {
			const isValidate = await validateAll()
			if (!isValidate) return
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					const params = {
						displayName: formDetail.value.displayName,
						username: formDetail.value.username,
						password: formDetail.value.password,
						gender: formDetail.value.gender,
						genderPreference: [],
						wactchedMovies: [],
						favoriteMovies: [],
						favoriteCategories: [],
						favoriteTags: []
					}
					const memberId = route.query.updateMemberId
					let response
					if (memberId) {
						response = await updateMember({ id: memberId, ...params })
					} else {
						response = await createMember(params)
					}
					if (response.status === 1000) {
						if (!memberId) {
							formDetail.value.displayName = '',
							formDetail.value.username = '',
							formDetail.value.password = '',
							formDetail.value.gender = ''
						}
						Swal.fire({
							title:'Upload Success !!',
							icon: 'success',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#BA3B1A'
						})
					} else if (response.status === 5000) {
						Swal.fire({
							title: 'username นี้ซ้ำในระบบ',
							icon: 'error',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#BA3B1A'
						})
					} else {
						Swal.fire({
							title: 'Something wrong',
							icon: 'error',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#BA3B1A'
						})
					}
				}
			})
		}
		const onSelectGender = (event) => {
			formDetail.value.gender = event
		}
		return {
			route,
			memberDetail,
			formDetail,
			errors,
			validate,
			validateAll,
			memberImageInput,
			handlerMemberImage,
			onUploadMemberImageClick,
			onSubmitForm,
			onSelectGender
		}
	}
})
</script>

<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
