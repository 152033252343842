<template>
	<div>
		<textarea
			:type="type"
			:placeholder="placeholder"
			:value="modelValue"
			class=" bg-phBlack  border h-full rounded pl-4 pt-4 w-full outline-none"
			:class="`${!!error ? ' border-phRed' : 'border-phAdminGrey'}`"
			:row="row"
			@input="handleEmit"
			@blur="handleValidate"
		/>
		<p
			v-if="!!error"
			:class="`${!!error ? ' text-phRed' : ''}`"
		>
			{{ error }}
		</p>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'PhTextArea',
	props: {
		modelValue: {
			type: [String, Number, Object],
			default: null
		},
		type: {
			type: String,
			default: 'text'
		},
		placeholder: {
			type: String,
			default: null
		},
		error: {
			type: null,
			default: null
		},
		row: {
			type: Number,
			default: 10
		}
	},
	emits: ['update:modelValue', 'validate'],
	setup(_, { emit }) {
		const handleEmit = (event) => {
			emit('update:modelValue', event.target.value)      
			handleValidate()      
		}

		const handleValidate = () => {
			emit('validate')
		}

		return { handleEmit, handleValidate }
	},
})
</script>
