<template>
	<div class=" bg-phBlack w-full h-full">
		<h1 class=" pt-6 px-6 text-2xl">
			Tags
		</h1>
		<hr class="w-full h-1 mt-4 border-phAdminGrey">
		<div class=" w-1/2">
			<!-- Content Zone -->
			<form
				class=" m-4 w-full"
				@submit.prevent="onAddTagSubmit"
			>
				<label
					for="addTag"
					class=" text-xl"
				>Add tags</label>
				<div class=" flex mt-4">
					<div class="w-full">
						<PhInput
							id="addTag"
							v-model="values.name"
							placeholder="Enter your tag name"
							:error="errors.name"
							@validate="validate('name')"
						/>
					</div>
					<PhButton
						type="submit"
						class="w-28 ml-4 h-11"
						:loading="values.isLoading"
					>
						Add
					</PhButton>
				</div>
				<div class="mt-2">
					<h2>Thumbnail</h2>
					<div class="flex mt-4 items-center">
						<div class="image-container">
							<img :src="values.image ? values.image:require('@/assets/icon/user-inactive.svg')">
						</div>
						<div class="image-input ml-6">
							<p>Upload thumbnail image 10mb Maximum</p>
							<input
								ref="memberImageInput"
								type="file"
								class="hidden"
								accept="image/*"
								@change="handlerMemberImage($event, 'create')"
								@validate="validate('imageFile')"
							>
							<PhButton
								class="mt-2 bg-phGray"
								@click="onUploadMemberImageClick"
							>
								Browse
							</PhButton>
						</div>
					</div>
					<p
						v-if="!values.image && errors.image"
						class="text-phRed mt-2"
					>
						{{ errors.image }}
					</p>
				</div>
			</form>
			<div class="w-full mt-5 p-4">
				<!-- Tags all -->
				<div class="mt-2 flex flex-wrap">
					<PhTag
						v-for="(tag, i) in tags"
						:key="`tag-${i}`"
						class=" p-1"
						:value="tag._id"
						:text="tag.name"
						@click.stop="onTagClick(tag)"
					>
						<template #custom>
							<div
								class=" hidden group-hover:flex absolute right-0 top-0 bg-white rounded-full p-1  items-center justify-center"
								@click.stop="deleteTag(tag)"
							>
								<i class="icon-Close text-phBlack" />
							</div>
						</template>
					</PhTag>
				</div>
			</div>
		</div>
		<!-- Modal -->
		<PhModal
			v-model="editModal"
			class="flex justify-center items-center"
		>
			<div class=" p-4 w-1/4 inline-block align-bottom bg-phBlack rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
				<h4>Edit</h4>
				<div class=" flex flex-col items-center">
					<PhInput
						id="editTag"
						v-model="editTag.name"
						placeholder="Enter your tag name"
						class=" w-full mt-4"
						:error="errorsEditTag.name"
						@validate="validateEditTag('name')"
					/>
					<div class="my-4">
						<h2>Thumbnail</h2>
						<div class="flex mt-4 items-center">
							<div class="image-container">
								<img :src="editTag.image ? editTag.image:require('@/assets/icon/user-inactive.svg')">
							</div>
							<div class="image-input ml-6">
								<p>Upload thumbnail image 10mb Maximum</p>
								<input
									ref="memberImageInput"
									type="file"
									class="hidden"
									accept="image/*"
									@change="handlerMemberImage($event, 'edit')"
									@validate="validate('imageFile')"
								>
								<PhButton
									class="mt-2 bg-phGray"
									@click="onUploadMemberImageClick"
								>
									Browse
								</PhButton>
							</div>
						</div>
						<p
							v-if="!editTag.image && errorsEditTag.image"
							class="text-phRed mt-2"
						>
							{{ errorsEditTag.image }}
						</p>
					</div>
					<div class=" flex mt-4">
						<button
							type="button"
							class=" text-black bg-white w-32 outline-none p-2 rounded"
							@click="editModal = false"
						>
							Cancel
						</button>
						<PhButton
							class=" w-40 ml-4"
							:loading="editTag.isLoading"
							@click="onEditSubmit"
						>
							Confirm
						</PhButton>
					</div>
				</div>
			</div>
		</PhModal>
		<!-- Configmt Modal -->
		<PhModal
			v-model="deleteModal"
			class=" flex items-center justify-center"
		>
			<div class=" p-4 w-1/4 inline-block align-bottom bg-phBlack rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
				<div class=" flex flex-col items-center mt-4 bg-phBlack">
					<h4 class=" text-3xl">
						Confirm Delete
					</h4>
					<div class=" mt-5">
						<button
							type="button"
							class=" text-black bg-white w-32 outline-none p-2 rounded"
							@click="deleteModal = false"
						>
							Cancel
						</button>
						<PhButton
							class=" w-40 ml-4"
							:loading="editTag.isLoading"
							@click="onDeleteConfirm"
						>
							Confirm
						</PhButton>
					</div>
				</div>
			</div>
		</PhModal>
		<!-- End Confirm Modal -->
	</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { getAllTag, addTagImage, editTagById, addTag, deleteTagById } from '@/api/admin/tag.api'
import PhTag from '@/components/global/PhTag.vue'
import PhButton from '@/components/global/PhButton.vue'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import Swal from 'sweetalert2'
import { checkFile } from '@/api/admin/movies.api'

export default defineComponent({
	components: {
		PhTag,
		PhButton
	},
	setup() {
		const tags = ref([])
		const editModal = ref(false)
		const deleteModal = ref(false)
		const deleteTagId = ref(null)
		const memberImageInput = ref(null)
		const { validate: validateEditTag, validateAll: validateEditAll, errors: errorsEditTag, values: editTag } = useValidation(
			{
				name: yup.string().required('Please enter Tag Name'),
				image: yup.string().required('Please select image')
			},
			{
				id: null,
				imageFile: null,
				name: '',
				image: '',
				isLoading: false
			}
		)

		const { validate, validateAll, errors, values } = useValidation(
			{
				name: yup.string().required('Please enter Tag Name'),
				image: yup.string().required('Please select image')
			},
			{
				id: null,
				imageFile: null,
				name: '',
				image: '',
				isLoading: false
			}
		)

		onMounted(() => {
			getInitialTag()
		})

		const getInitialTag = () => {
			getAllTag().then(({ message }) => tags.value = message.data.tags)
		}

		const onAddTagSubmit = async () => {
			//
			const isValid = await validateAll()
			if (!isValid) return
			const { status, message: { data } } = await addTagImage({ file: values.value.imageFile })
			if (status === 1000) {
				let m = setInterval(async () => {
					let imgPath = await checkFile(data._id)
					if (imgPath.message.data.path) {
						clearInterval(m)
						const params = {
							id: values.value.id,
							name: values.value.name,
							image: imgPath.message.data.path
						}
						const tagResponse = await addTag(params)
						if (tagResponse.status === 1000) {
							values.value.name = ''
							values.value.image = ''
							values.value.imageFile = ''
							Swal.fire({
								title:'Add tag Success',
								icon: 'success',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#BA3B1A'
							})
						} else {
							Swal.fire({
								title:'Add tag Failed !!',
								icon: 'error',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#BA3B1A'
							})
						}
						// getInitialTag()
						values.value.isLoading = false
						tags.value.push(tagResponse.message.data)
					}
				}, 2000)
			} else {
				Swal.fire({
					title:'Upload Failed !!',
					icon: 'error',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#BA3B1A'
				})
			}
		}

		const onEditSubmit = async () => {
			//
			editTag.value.isLoading = true
			const isValid = await validateEditAll()
			if (!isValid) return
			const { status, message: { data } } = await addTagImage({ file: editTag.value.imageFile })
			const params = {
				id: editTag.value.id,
				name: editTag.value.name,
				image: data.Location
			}
			const response = await editTagById(params)
			if (response.status === 1000) {
				editTag.value.name = ''
				editTag.value.image = ''
				editTag.value.imageFile = ''
				editTag.value.isLoading = false
			} else {
				Swal.fire({
					title:'Add category Failed !!',
					icon: 'error',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#BA3B1A'
				})
				editTag.value.isLoading = false
			}
			editModal.value = false
			getInitialTag() // get lastest tag
		}

		const onTagClick = (tag) => {
			editModal.value = true
			editTag.value.id = tag._id
			editTag.value.name = tag.name
			editTag.value.image = tag.image
		}

		const deleteTag = (tag) => {
			deleteTagId.value = tag._id
			deleteModal.value = true
		}

		const onDeleteConfirm = async () => {
			const tagId = deleteTagId.value
			await deleteTagById(tagId)
			deleteModal.value = false
			getInitialTag()
		}

		const onUploadMemberImageClick = () => {
			memberImageInput.value.click()
		}

		const handlerMemberImage = (event, mode) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				if (mode === 'create') {
					values.value.image = e.target.result
				} else {
					editTag.value.image = e.target.result
				}
			}
			if (mode === 'create') {
				values.value.imageFile = files
			} else {
				editTag.value.imageFile = files
			}
			reader.readAsDataURL(files)
			event.target.value = ''
		}

		return {
			tags,
			editModal,
			editTag,
			onEditSubmit,
			validateEditTag,
			errorsEditTag,
			onTagClick,
			onAddTagSubmit,
			validate,
			validateAll,
			errors,
			values,
			deleteTag,
			deleteModal,
			onDeleteConfirm,
			memberImageInput,
			handlerMemberImage,
			onUploadMemberImageClick
		}
	},
})
</script>
<style lang="scss" scoped>
.image-container {
	width: 75px;
	height: 75px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: content;
	}
}
</style>

<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
