import { createStore } from 'vuex'
import authStore from '@/store/modules/authStore'
import createPersistedState from 'vuex-persistedstate'

const persistedOption = createPersistedState({
	key: 'ph-admin',
	paths: ['authStore.token']
})


export default createStore({
	plugins: [persistedOption],
	modules: {
		authStore
	},
})
