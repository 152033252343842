import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

export type AAdvertoria = {
	name: string,
	image: string,
	ratioW: number,
	ratioH: number,
	position: string,
	page: string
}

export type PPage = {
	page: number
}
export type IId = {
	id: string
}

export type FFile = {
    file: File,
    onUploadProgress?: () => unknown
}
interface AAdvertoriaEdit extends AAdvertoria {
	id: string
}

export const createAdvertoria = (params: AAdvertoria): Promise<AxiosResponse> => {
	return axiosInstance.post('/advertises', { ...params }).then(response => response.data)
}

export const uploadAdvertiseImage = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/advertises', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}

export const updateAdvertoria = (params: AAdvertoriaEdit): Promise<AxiosResponse> => {
	return axiosInstance.put('/advertises', params).then(response => response.data)
}

export const getAdvertorias = (params: PPage): Promise<AxiosResponse> =>
	axiosInstance.get('/advertises', {
		params
	}).then(response => response.data)

export const deleteAdvertoria = (data: IId): Promise<AxiosResponse> =>
	axiosInstance.delete('/advertises', {
		data
	}).then(response => response.data)
