<template>
	<div class=" bg-phBlack w-full h-full">
		<h1 class=" pt-6 px-6 text-2xl">
			Categories
		</h1>
		<hr class="w-full h-1 mt-4 border-phAdminGrey">
		<div class=" w-1/2">
			<!-- Content Zone -->
			<form
				class=" m-4 w-full"
				@submit.prevent="onAddCategorySubmit"
			>
				<label
					for="addCategory"
					class=" text-xl"
				>Add categories</label>
				<div class=" flex mt-4">
					<div class="w-full">
						<PhInput
							id="addCategory"
							v-model="values.name"
							placeholder="Enter your category name"
							:error="errors.name"
							@validate="validate('name')"
						/>
					</div>
					<PhButton
						type="submit"
						class="  w-28 ml-4 h-11"
						:loading="values.isLoading"
					>
						Add
					</PhButton>
				</div>
				<div class="mt-2">
					<h2>Thumbnail</h2>
					<div class="flex mt-4 items-center">
						<div class="image-container">
							<img :src="values.image ? values.image:require('@/assets/icon/user-inactive.svg')">
						</div>
						<div class="image-input ml-6">
							<p>Upload thumbnail image 10mb Maximum</p>
							<input
								ref="memberImageInput"
								type="file"
								class="hidden"
								accept="image/*"
								@change="handlerMemberImage($event, 'create')"
								@validate="validate('imageFile')"
							>
							<PhButton
								class="mt-2 bg-phGray"
								@click="onUploadMemberImageClick"
							>
								Browse
							</PhButton>
						</div>
					</div>
					<p
						v-if="!values.image && errors.image"
						class="text-phRed mt-2"
					>
						{{ errors.image }}
					</p>
				</div>
			</form>
			<div class="w-full mt-5 p-4">
				<!-- Tags all -->
				<div class="mt-2 flex flex-wrap">
					<PhTag
						v-for="(cat, i) in categories"
						:key="`cat-${i}`"
						class=" p-1"
						:value="cat['_id']"
						:text="cat.name"
						@click.stop="onCategoryClick(cat)"
					>
						<template #custom>
							<div
								class=" hidden group-hover:flex absolute right-0 top-0 bg-white rounded-full p-1  items-center justify-center"
								@click.stop="deleteCategory(cat)"
							>
								<i class="icon-Close text-phBlack" />
							</div>
						</template>
					</PhTag>
				</div>
			</div>
		</div>
		<!-- Modal -->
		<PhModal
			v-model="editModal"
			class="flex justify-center items-center"
		>
			<div class=" p-4 w-1/4 inline-block align-bottom bg-phBlack rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
				<h4>Edit</h4>
				<div class=" flex flex-col items-center">
					<PhInput
						id="editCategory"
						v-model="editCategory.name"
						placeholder="Enter your tag name"
						class=" w-full mt-4"
						:error="errorsEditCategory.name"
						@validate="validateEditCategory('name')"
					/>
					<div class="my-4">
						<h2>Thumbnail</h2>
						<div class="flex mt-4 items-center">
							<div class="image-container">
								<img :src="editCategory.image ? editCategory.image:require('@/assets/icon/user-inactive.svg')">
							</div>
							<div class="image-input ml-6">
								<p>Upload thumbnail image 10mb Maximum</p>
								<input
									ref="memberImageInput"
									type="file"
									class="hidden"
									accept="image/*"
									@change="handlerMemberImage($event, 'edit')"
									@validate="validate('imageFile')"
								>
								<PhButton
									class="mt-2 bg-phGray"
									@click="onUploadMemberImageClick"
								>
									Browse
								</PhButton>
							</div>
						</div>
						<p
							v-if="!editCategory.image && errorsEditCategory.image"
							class="text-phRed mt-2"
						>
							{{ errorsEditCategory.image }}
						</p>
					</div>
					<div class=" flex mt-4">
						<button
							type="button"
							class=" text-black bg-white w-32 outline-none p-2 rounded"
							@click="editModal = false"
						>
							Cancel
						</button>
						<PhButton
							class=" w-40 ml-4"
							:loading="editCategory.isLoading"
							@click="onEditSubmit"
						>
							Confirm
						</PhButton>
					</div>
				</div>
			</div>
		</PhModal>
		<!-- Configmt Modal -->
		<PhModal
			v-model="deleteModal"
			class=" flex items-center justify-center"
		>
			<div class=" p-4 w-1/4 inline-block align-bottom bg-phBlack rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
				<div class=" flex flex-col items-center mt-4 bg-phBlack">
					<h4 class=" text-3xl">
						Confirm Delete
					</h4>
					<div class=" mt-5">
						<button
							type="button"
							class=" text-black bg-white w-32 outline-none p-2 rounded"
							@click="deleteModal = false"
						>
							Cancel
						</button>
						<PhButton
							class=" w-40 ml-4"
							:loading="editCategory.isLoading"
							@click="onDeleteConfirm"
						>
							Confirm
						</PhButton>
					</div>
				</div>
			</div>
		</PhModal>
		<!-- End Confirm Modal -->
	</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { getAllCategories, addCategoryImage, editCategoryById, addCategory, deleteCategoryById} from '@/api/admin/category.api'
import { checkFile } from '@/api/admin/movies.api'
import PhTag from '@/components/global/PhTag.vue'
import PhButton from '@/components/global/PhButton.vue'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import Swal from 'sweetalert2'

export default defineComponent({
	components: { PhTag, PhButton },
	setup() {
		const categories = ref([])
		const editModal = ref(false)
		const deleteModal = ref(false)
		const deleteCategoryId = ref(null)
		const memberImageInput = ref(null)
		const { validate: validateEditCategory, validateAll: validateEditAll, errors: errorsEditCategory, values: editCategory } = useValidation(
			{
				name: yup.string().required('Please enter Category Name'),
				image: yup.string().required('Please select image')
			},
			{
				id: null,
				name: '',
				imageFile: null,
				image: '',
				isLoading: false
			}
		)

		const { validate, validateAll, errors, values } = useValidation(
			{
				name: yup.string().required('Please enter Category Name'),
				image: yup.string().required('Please select image')
			},
			{
				id: null,
				imageFile: null,
				name: '',
				image: '',
				isLoading: false
			}
		)

		onMounted(() => {
			getInitalCategories()
		})

		const getInitalCategories = () => {
			getAllCategories().then(({ message }) => categories.value = message.data.categories)
		}

		const onAddCategorySubmit = async () => {
			const isValid = await validateAll()
			if (!isValid) return
			const { status, message: { data } } = await addCategoryImage({ file: values.value.imageFile })
			if (status === 1000) {
				let m = setInterval(async () => {
					let imgPath = await checkFile(data._id)
					if (imgPath.message.data.path) {
						clearInterval(m)
						const params = {
							id: values.value.id,
							name: values.value.name,
							image: imgPath.message.data.path
						}
						const response = await addCategory(params)
						if (response.status === 1000) {
							values.value.name = ''
							values.value.image = ''
							values.value.imageFile = ''
						} else {
							Swal.fire({
								title:'Add category Failed !!',
								icon: 'error',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#BA3B1A'
							})
						}
						// getInitialTag()
						values.value.isLoading = false
						categories.value.push(response.message.data)
					}
				}, 2000)
			} else {
				Swal.fire({
					title:'Upload Failed !!',
					icon: 'error',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#BA3B1A'
				})
			}
		}

		const onEditSubmit = async () => {
			//
			editCategory.value.isLoading = true
			const isValid = await validateEditAll()
			if (!isValid) return
			const { status, message: { data } } = await addCategoryImage({ file: editCategory.value.imageFile })
			const params = {
				id: editCategory.value.id,
				name: editCategory.value.name,
				image: data.Location
			}
			const response = await editCategoryById(params)
			if (response.status === 1000) {
				editCategory.value.name = ''
				editCategory.value.image = ''
				editCategory.value.imageFile = ''
				editCategory.value.isLoading = false
			} else {
				Swal.fire({
					title:'Add category Failed !!',
					icon: 'error',
					confirmButtonText: 'Ok',
					confirmButtonColor: '#BA3B1A'
				})
				editCategory.value.isLoading = false
			}
			editModal.value = false
			getInitalCategories()
		}

		const onCategoryClick = (category) => {
			editModal.value = true
			editCategory.value.id = category._id
			editCategory.value.name = category.name
			editCategory.value.image = category.image
		}

		const deleteCategory = (category) => {
			deleteCategoryId.value = category._id
			deleteModal.value = true
		}

		const onDeleteConfirm = async () => {
			const categoryId = deleteCategoryId.value
			await deleteCategoryById(categoryId)
			deleteModal.value = false
			getInitalCategories()
		}


		const onUploadMemberImageClick = () => {
			memberImageInput.value.click()
		}

		const handlerMemberImage = (event, mode) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				if (mode === 'create') {
					values.value.image = e.target.result
				} else {
					editCategory.value.image = e.target.result
				}
			}
			if (mode === 'create') {
				values.value.imageFile = files
			} else {
				editCategory.value.imageFile = files
			}
			reader.readAsDataURL(files)
			event.target.value = ''
		}

		return {
			categories,
			editModal,
			editCategory,
			onEditSubmit,
			validateEditCategory,
			errorsEditCategory,
			onCategoryClick,
			onAddCategorySubmit,
			validate,
			validateAll,
			errors,
			values,
			deleteCategory,
			deleteModal,
			memberImageInput,
			handlerMemberImage,
			onUploadMemberImageClick,
			onDeleteConfirm
		}
	},
})
</script>
<style lang="scss" scoped>
.image-container {
	width: 75px;
	height: 75px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: content;
	}
}
</style>

<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
