<template>
	<div>
		<div class="flex items-center">
			<h1>Video Page</h1>
			<p class="ml-auto">URL :</p>
			<PhInput
				v-model="url"
				class="ml-4"
			/>
			<PhButton
				class="w-36 text-base text-white ml-4"
				@click="onSaveChange"
				:loading="isLoading"
			>
				Save
			</PhButton>
		</div>
		<PhTable
			:data="tableData.data"
			:headers="tableData.headers"
			:offset-list="tableData.countList"
			:status-list="tableData.statusList"
			:offset="tableData.offset"
			class="movie-table"
		>
			<template #content-preview="{ item }">
				<img
					:src="item"
					class="mx-auto"
					loading="lazy"
					width="50"
					height="50"
				>
			</template>
			<template #content-categories="{ item }">
				<p>{{ item.toString() }}</p>
			</template>
			<template #content-edit="{ item }">
				<PhButton
					bg-class="bg-yellow-400"
					bg-hover-class="bg-yellow-600"
					@click="onEditRow(item)"
				>
					Edit
				</PhButton>
			</template>
			<template #content-movieId="{ item }">
				<PhButton @click="onDeleteRow(item)">
					Delete
				</PhButton>
			</template>
		</PhTable>
	</div>
</template>

<script>
import { defineComponent, nextTick, onMounted, reactive, ref } from 'vue'
import PhTable from '@/components/global/PhTable.vue'
import { getAllMovies, deleteMovie, replaceUrl } from '@/api/admin/movies.api'
import { getAllCategories } from '@/api/admin/category.api'
import { getUrl } from '@/api/admin/config.api'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export default defineComponent({
	components: { PhTable },
	setup () {
		const router = useRouter()
		let url = ref('')
		const isLoading = ref(false)
		const tableData = reactive({
			data: [],
			headers: [
				{
					label: 'Name',
					key: 'title'
				},
				{
					label: 'Category',
					key: 'categories'
				},
				{
					label: 'View',
					key: 'watched'
				},
				{
					label: 'Movie cover',
					key: 'preview'
				},
				{
					label: 'Date',
					key: 'date'
				},
				// {
				//      label: 'Author',
				//      key: 'author'
				// },
				// {
				//      label: 'Status',
				//      key: 'status'
				// },
				{
					label: 'Edit',
					key: 'edit'
				},
				{
					label: 'Delete',
					key: 'movieId'
				}
			],
			countList: [
				{
					label: '10',
					value: 10
				},
				{
					label: '25',
					value: 25
				},
				{
					label: '50',
					value: 50
				}
			],
			statusList: [
				{
					label: 'All Status',
					status: 0
				},
				{
					label: 'Actor',
					status: 1
				},
				{
					label: 'customer',
					status: 2
				}
			]
		})
		onMounted(() => {
			nextTick(() => {
				getAllCategories({ page: 1 }).then(categoriesRespone => {
					getAllMovies({ page: 1, size: 1000 }).then(movieResponse => {
						getUrl({ name: 'url' }).then(response => {
							url.value = response.message.data.value
							console.log(url)
						})
						console.log('MOVIE :: ', movieResponse)
						if (movieResponse.status === 1000) {
							tableData.data = movieResponse.message.data.movies.map(movie => {
								return {
									...movie,
									edit: movie._id,
									movieId: movie._id,
									categories: categoriesRespone.message.data.categories.filter(category => movie.categories.includes(category._id)).map(cat => cat.name),
									date: dayjs(movie.update_at).format('DD/MM/YYYY'),
									watched: movie.watched
								}
							})
						}
					})
				})
			})
		})
		// const onEditStatus = () => {
		// console.log('Edit status')
		// }
		const onDeleteRow = (item) => {
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then((result) => {
				if (result.isConfirmed) {
					deleteMovie({ id: item }).then((response => {
						if (response.status === 1000) {
							const tableList = tableData.data
							tableData.data = tableList.filter(item => item._id !== response.message.data)
						} else {
							Swal.fire({
								title: response.message.error,
								icon: 'error',
								confirmButtonText: 'Ok',
								confirmButtonColor: '#BA3B1A'
							})
						}
					}))
				}
			})
		}

		const onSaveChange = () => {
			isLoading.value = true
			const data = {
				to: url.value
			}
			replaceUrl(data).then(res => {
				isLoading.value = false
				Swal.fire({
					title: 'บันทึกลิงค์สำเร็จ',
					icon: 'success',
				})
			})
		}

		const onEditRow = (item) => {
			return router.push({ path: '/videos/upload', query: { updateVideoId: item } })
		}

		return {
			tableData,
			url,
			onSaveChange,
			onEditRow,
			onDeleteRow,
			isLoading
			// onRowClick
		}
	}
})
</script>
<style lang="scss" scoped>
::v-deep.movie-table {
    .header-preview {
        text-align: center;
    }
    .header-categories {
        width: 300px;
    }
    .content-categories {
        width: 300px;
    }
}
</style>

<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
