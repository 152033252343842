import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

export type MMember = {
    displayName: string,
	username: string,
	passowrd: string,
	gender: string,
	genderPreference: Array<string>,
	wactchedMovies: Array<string>,
	favoriteMovies: Array<string>,
	favoriteCategories: Array<string>,
	favoriteTags: Array<string>
}

export type PPage = {
	page: number
}
export type IId = {
	id: string
}

interface MMemberEdit extends MMember {
	id: string
}
export const getMemberById = (userId: string): Promise<AxiosResponse> =>
	axiosInstance.get(`/users/${userId}`).then(response => response.data)

export const createMember = (params: MMember): Promise<AxiosResponse> => {
	return axiosInstance.post('/users', { ...params }).then(response => response.data)
}

export const updateMember = (params: MMemberEdit): Promise<AxiosResponse> => {
	return axiosInstance.put('/users', params).then(response => response.data)
}

export const getAllMember = (params: PPage): Promise<AxiosResponse> =>
	axiosInstance.get('/users', {
		params
	}).then(response => response.data)

export const deleteMember = (data: IId): Promise<AxiosResponse> => 
	axiosInstance.delete('/users', {
		data
	}).then(response => response.data)
