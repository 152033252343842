import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import store from '@/store'
import router from '@/router'
import useEnv from '@/use/useEnv'

const configs = JSON.parse(useEnv('VUE_APP_PH_ADMIN_CONFIGS'))

const apiInstance: AxiosInstance = axios.create({
	baseURL: configs.baseURL
}) 

apiInstance.interceptors.request.use((configs: AxiosRequestConfig): AxiosRequestConfig => {
	const authToken = (store.state as any).authStore.token
	if (authToken) {
		configs.headers = {
			...configs.headers,
			Authorization: `Bearer ${authToken}`
		}
	}
	return configs
})

apiInstance.interceptors.response.use(
	(response) => {        
		return response
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
				// clear token then redirect
				store.commit('authStore/updateAuthToken', null)
				return router.push('/login')
			}
		}
		return Promise.reject(error)
	}
)

export default apiInstance