<template>
	<div>
		<h1 class="mx-10 mt-5 pb-10 border-b-2 border-phGray font-bold text-phGray text-2xl">
			Dasboard
		</h1>
		<h2 class="mx-10 mt-10 mb-4 font-bold text-phGray">
			User Traffic {{ labels }}
		</h2>
		<div class="flex">
			<div class="max-w-4xl w-full ml-10 flex">
				<Vue3ChartJs
					v-if="chartDetail"
					:id="chartDetail.id"
					class="bg-phBlack p-4 rounded"
					:type="chartDetail.type"
					v-model:data="chartDetail.data"
					@before-render="beforeRenderLogic"
					ref="ChartRef"
				/>
			</div>
			<div class="flex flex-1 flex-col justify-between">
				<div
					class="bg-phBlack max-w-sm w-full mx-auto p-8 text-center rounded flex items-center flex-col justify-center"
					style="height: 45%;"
				>
					<h3>จำนวนผู้เข้ารับชมวิดีโอประจำวัน</h3>
					<p class="mt-6 text-phRed font-bold text-2xl">
						{{ userWatched }}
					</p>
				</div>
				<div
					class="bg-phBlack max-w-sm w-full mx-auto p-8 text-center rounded flex items-center flex-col justify-center"
					style="height: 45%;"
				>
					<h3>จำนวนผู้ใช้งานเว็บไซต์ประจำวัน</h3>
					<p class="mt-6 text-phRed font-bold text-2xl">
						4,787
					</p>
				</div>
			</div>
		</div>
		<div class="m-10 w-11/12">
			<h2 class="font-bold text-phGray">
				Top 10 movies
			</h2>
			<table class="w-full">
				<tr
					v-for="(item, i) in topMovies"
					:key="i"
					class="border-b-2 border-phGray"
				>
					<td class="align-bottom w-1/12">
						{{ i + 1 }}.
					</td>
					<td class="align-bottom w-5/12">
						{{ item.movieId.title }}
					</td>
					<td class="align-bottom w-4/12">
						{{ item.movieId.tags }}
					</td>
					<td class="align-bottom none w-1/12 text-phRed">
						{{ item.watched }} Views
					</td>
					<td class="w-1/12">
						<img
							class="px-2 my-2 ml-auto"
							style="width: 120px; height: 60px;"
							:src="item.movieId.preview"
						>
					</td>
				</tr>
			</table>
		</div>
		<!-- <div class="m-10 w-11/12">
			<h2 class="font-bold text-phGray">
				Top 10 movies
			</h2>
			<table class="w-full">
				<tr
					v-for="i in 4"
					:key="i"
					class="border-b-2 border-phGray"
				>
					<td class="align-bottom">
						{{ i }}.
					</td>
					<td class="align-bottom">
						Cras eget luctus leo. Cras tempor sed nunc et
					</td>
					<td class="align-bottom">
						Asian, Straight
					</td>
					<td>
						<img
							class="my-2 ml-auto"
							style="width: 100px; height: 60px;"
							src="https://picsum.photos/200"
						>
					</td>
				</tr>
			</table>
		</div> -->
	</div>
</template>
<script>
import { defineComponent, onMounted, reactive, computed, ref } from 'vue'
import { movieReport, engagementReport } from '@/api/admin/report.api'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import dayjs from 'dayjs'

export default defineComponent({
	components: {
		Vue3ChartJs
	},
	setup () {
		let ChartRef = ref()
		let topMovies = ref([])
		let userWatched = ref()
		let chartDetail = ref()
		

		const beforeRenderLogic = (event) => {
			//...
		}

		const numberWithCommas = (x) => {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		}

		onMounted(() => {
			let ctx = document.createElement('canvas').getContext('2d')
			let gradientPattern = ctx.createLinearGradient(0, 0, 0, 400)
			gradientPattern.addColorStop(0, '#e50815')
			gradientPattern.addColorStop(1, 'transparent')
			chartDetail.value = {
				id: 'chart',
				type: 'line',
				data: {
					labels: Array(24).fill().map((x,i)=> String(i).padStart(2, '0') + ':00'),
					datasets: [
						{
							label: 'User Traffic',
							backgroundColor: gradientPattern,
							borderColor: '#e50815',
							pointBorderColor: '#e50815',
							pointBackgroundColor: '#e50815',
							pointHoverBackgroundColor: '#fff',
							pointHoverBorderColor: '#fff',
							fill: {
								target: 'origin',
							},
							data: Array(24).fill().map((x,i)=> x),
							tension: 0.5
						}
					]
				}
			}
			getReportMovie()
			getReportEngage()
		})

		const getReportEngage = () => {
			let params = {
				day: Number(dayjs().format('DD')),
				month: Number(dayjs().format('MM')),
				year: Number(dayjs().format('YYYY'))
			}
			engagementReport(params).then(res => {
				userWatched.value = numberWithCommas(res.message.data.sum)
				let count = 0
				res.message.data.report.forEach(each => {
					if (each.hour) {
						chartDetail.value.data.datasets[0].data[each.hour] = each.watched
					}
				})
				ChartRef.value.update()
			})
		}

		const getReportMovie = () => {
			let params = {
				day: Number(dayjs().format('DD')),
				month: Number(dayjs().format('MM')),
				year: Number(dayjs().format('YYYY'))
			}
			// console.log(params)
			movieReport(params).then(res => {
				topMovies.value = res.message.data.map(each => {
					return {
						...each,
						movieId: {
							...each.movieId,
							tags: each.movieId.tags.map(item => item.name).join(', ')
						}
					}
				})
			})
		}

		return {
			chartDetail,
			beforeRenderLogic,
			getReportMovie,
			ChartRef,
			topMovies,
			getReportEngage,
			userWatched
		}
	}

})
</script>
