import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// Layout sidebar
import Sidebar from '@/layouts/Sidebar.vue'
import authMiddleware from '@/middlewares/auth.middleware'

const routes: RouteRecordRaw[] = [
	{
		path: '/login',
		name: 'LoginPage',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/',
		name: 'MainPage',
		component: Sidebar,
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/dashboard.vue')
			},
			{
				path: 'tag',
				name: 'Tag',
				component: () => import('@/views/tag.vue')
			},
			{
				path: 'category',
				name: 'Category',
				component: () => import('@/views/category.vue')
			}
		]
	},
	{
		path: '/user',
		name: 'User',
		component: Sidebar,
		children: [
			{
				path: '',
				name: 'User',
				component: () => import('@/views/user/index.vue'),
			},
			{
				path: 'add',
				name: 'UserAdd',
				component: () => import('@/views/user/add.vue'),
			}
		]
	},
	{
		path: '/videos',
		name: 'VideoPage',
		component: Sidebar,
		children: [
			{
				path: '',
				name: 'VideoMain',
				component: () => import('@/views/videos/index.vue')
			},
			{
				path: 'upload',
				name: 'VideoUpload',
				component: () => import('@/views/videos/upload.vue'),
				props: true
			}
		]
	},
	{
		path: '/highlight',
		name: 'HighlightPage',
		component: Sidebar,
		children: [
			{
				path: '',
				name: 'HighlightMain',
				component: () => import('@/views/highlight/index.vue')
			},
			{
				path: 'upload',
				name: 'HighlightUpload',
				component: () => import('@/views/highlight/upload.vue'),
				props: true
			}
		]
	},
	{
		path: '/advertoria',
		name: 'AdvertoriaPage',
		component: Sidebar,
		children: [
			{
				path: '',
				name: 'AdvertoriaMain',
				component: () => import('@/views/advertoria/index.vue')
			},
			{
				path: 'large-leader-board',
				name: 'LargeLeaderBoard',
				component: () => import('@/views/advertoria/leaderboard.vue')
			},
			{
				path: 'rectangle',
				name: 'Rectangle',
				component: () => import('@/views/advertoria/rectangle.vue')
			}
		]
	}
]


const router = createRouter({
	history: createWebHistory(),
	routes,
})
// Middleware set
router.beforeEach((to, from) => {
	const isAuth = authMiddleware(to, from)
	if (!isAuth) return '/login' // Redirect to Login page :)
})

export default router
