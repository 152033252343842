<template>
	<div class="ph-select">
		<select
			class="w-full bg-phBlack h-12 border-2 rounded border-phAdminGrey text-white"
			:placeholder="placeholder"
			:value="modelValue"
			@change="handleEmit"
			@blur="handleValidate"
		>
			<option
				v-for="(item, index) in items"
				:key="index"
				:value="item"
				class="text-white"
			>
				{{ item.text }}
			</option>
		</select>
		<p
			v-if="!!error && !modelValue"
			:class="`${!!error ? ' text-phRed' : ''}`"
		>
			{{ error }}
		</p>
	</div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
	name: 'PhSelect',
	props: {
		modelValue: {
			type: [String, Number, Object],
			default: null
		},
		items: {
			type: Array,
			default: () => []
		},
		error: {
			type: null,
			default: null
		},
		placeholder: {
			type: String,
			default: 'text'
		}
	},
	emits: ['update:modelValue', 'validate'],
	setup(props, { emit }) {
		const { modelValue, label, value } = toRefs(props)
		const dropdown = reactive({
			toggle: false
		})
		const handleEmit = (event) => {
			emit('update:modelValue', event.target.value)
			handleValidate()
		}
		const handleValidate = () => {
			emit('validate')
		}

		const onToggleDropdown = () => {
			dropdown.toggle = !dropdown.toggle
		}
		const onSelectItem = (item) => {
			emit('update:modelValue', item[value.value])
			dropdown.toggle = false
		}
		return {
			handleEmit,
			handleValidate,
			dropdown,
			onToggleDropdown,
			onSelectItem
		}
	}
})
</script>
