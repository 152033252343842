import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

export type FFile = {
    file: File,
    onUploadProgress: () => unknown
}
export type Mhighlights = {
    title: string,
    description: string,
    path: string,
}

export type PPage = {
    page: number
}

export type IId = {
    id: string
}

export const getAllhighlights = (params: PPage): Promise<AxiosResponse> => 
	axiosInstance.get('/highlights', {
		params
	}).then(response => response.data)

export const getMovieById = (movieId: string): Promise<AxiosResponse> =>
	axiosInstance.get(`/highlights/${movieId}`).then(response => response.data)

export const deleteMovie = (data: IId): Promise<AxiosResponse> => 
	axiosInstance.delete('/highlights', {
		data
	}).then(response => response.data)

export const uploadVideoPreviewImage = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/preview', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}

export const uploadVideo = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/videos', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}

export const checkFile = (params: string): Promise<AxiosResponse> => {
	return axiosInstance.get('/file/' + params).then(response => response.data)
}

export const createMovie = (params: Mhighlights): Promise<AxiosResponse> => {
	return axiosInstance.post('/highlights', { ...params }).then(response => response.data)
}

interface MMovieEdit extends Mhighlights {
	id: string
}

export const updateMovie = (params: MMovieEdit): Promise<AxiosResponse> => {
	return axiosInstance.put('/highlights', params).then(response => response.data)
}