import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

type LoginPayload = {
    day: number,
    month: number,
	year: number
}

export const movieReport = (params: LoginPayload): Promise<AxiosResponse> => 
	axiosInstance.get('/reports/movies/top', { params }).then(response => response.data)

export const engagementReport = (params: LoginPayload): Promise<AxiosResponse> => 
	axiosInstance.get('/reports', { params }).then(response => response.data)
