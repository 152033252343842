import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

type LoginPayload = {
    username: string,
    password: string
}

export const login = (payload: LoginPayload): Promise<AxiosResponse> => 
	axiosInstance.post('/admin/auth/login', payload).then(response => response.data)
