<template>
	<div class="m-5 ">
		<header>
			<h1 class="font-bold text-2xl text-phAdminGrey border-b-2 pb-3 border-phGray">
				Advertoria
			</h1>
			<h2 class="mt-10 font-bold text-phAdminGrey">
				Large Leaderboard
			</h2>
		</header>
		<main class="flex mt-5">
			<div class="pt-12 mr-10">
				<img
					src="@/assets/leader.svg"
					width="200"
				/>
			</div>
			<form
				class="flex-1"
				@submit.prevent="onSubmit"
			>
				<h3 class="font-bold text-phAdminGrey mb-2">
					Name
				</h3>
				<PhInput
					v-model="values.name"
					class="w-2/3 mb-5"
					placeholder="Please enter advertoria name"
					:error="errors.name"
					@validate="validate('name')"
				/>
				<h3 class="font-bold text-phAdminGrey mb-2">
					URL
				</h3>
				<PhInput
					v-model="values.url"
					class="w-2/3 mb-5"
					placeholder="Please enter advertoria url"
					:error="errors.url"
					@validate="validate('url')"
				/>
				<h3 class="font-bold text-phAdminGrey">
					Desktop
				</h3>
				<p class="text-phGray mb-4">
					This image will display only desktop, tablet
				</p>
				<div
					v-if="values.desktopImage"
					class="w-2/3 mb-5 text-center"
				>
					<img
						:src="values.desktopImage"
						style="object-fit: content;"
					>
				</div>
				<div
					v-else
					class="w-2/3 mb-5 text-center border-4 border-phGrey border-dotted rounded-md p-8 text-phGray"
				>
					Recommend size 970x90
				</div>
				<p
					v-if="!values.desktopImage && errors.desktopImage"
					class="text-phRed mt-2"
				>
					{{ errors.desktopImage }}
				</p>
				<input
					ref="desktopImageInput"
					type="file"
					class="hidden"
					accept="image/*"
					@change="handlerDesktopImage($event)"
					@validate="validate('desktopImageFile')"
				>
				<PhButton
					class="mb-5 font-bold"
					type="button"
					@click="onUploadDesktopImageClick"
				>
					Browse
				</PhButton>
				<h3 class="font-bold text-phAdminGrey">
					Mobile
				</h3>
				<p class="text-phGray mb-4">
					This image will display only mobile
				</p>
				<div
					v-if="values.mobileImage"
					class="w-2/3 mb-5 text-center"
				>
					<img
						:src="values.mobileImage"
						style="object-fit: content;"
					>
				</div>
				<div
					v-else
					class="w-2/3 mb-5 text-center border-4 border-phGrey border-dotted rounded-md p-8 text-phGray"
				>
					Recommend size 250x250
				</div>
				<p
					v-if="!values.mobileImage && errors.mobileImage"
					class="text-phRed mt-2"
				>
					{{ errors.mobileImage }}
				</p>
				<input
					ref="mobileImageInput"
					type="file"
					class="hidden"
					accept="image/*"
					@change="handlerMobileImage($event)"
					@validate="validate('mobileImageFile')"
				>
				<PhButton
					class="mb-5 font-bold"
					type="button"
					@click="onUploadMobileImageClick"
				>
					Browse
				</PhButton>
				<!-- <h3 class="font-bold text-phAdminGrey mb-5"> -->
				<!-- Position -->
				<!-- </h3> -->
				<!-- <PhSelect -->
				<!-- v-model="values.position" -->
				<!-- :items="positionList" -->
				<!-- :error="errors.position" -->
				<!-- label="label" -->
				<!-- value="value" -->
				<!-- placeholder="Plase select position" -->
				<!-- class="w-2/3 mb-5" -->
				<!-- @validate="validate('position')" -->
				<!-- /> -->
				<h3 class="font-bold text-phAdminGrey mb-5">
					Page
				</h3>
				<PhSelect
					v-model="values.page"
					:items="pageList"
					:error="errors.page"
					label="label"
					value="value"
					placeholder="Plase select page"
					class="w-2/3"
					@validate="validate('page')"
				/>
				<PhButton
					class="mt-10 w-2/3"
					type="submit"
				>
					Add
				</PhButton>
			</form>
		</main>
	</div>
</template>

<script>
import { defineComponent, nextTick, ref, reactive } from 'vue'
import PhSelect from '@/components/global/PhSelect.vue'
import { uploadAdvertiseImage, createAdvertoria } from '@/api/admin/advertoria.api'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import Swal from 'sweetalert2'
import { checkFile } from '@/api/admin/movies.api'

export default defineComponent({
	components: {
		PhSelect
	},
	setup() {
		const { validate, validateAll, errors, values } = useValidation(
			{
				name: yup.string().required('Please enter Advertoria Name'),
				desktopImage: yup.string().required('Please select image'),
				mobileImage: yup.string().required('Please select image'),
				url: yup.string().required('Please enter Advertoria Url'),
				page: yup.string().required('Please select page')
			},
			{
				id: null,
				desktopImageFile: null,
				mobileImageFile: null,
				url: '',
				name: '',
				desktopImage: '',
				mobileImage: '',
				ratioW: '',
				ratioH: '',
				position: '',
				page: '',
				isLoading: false
			}
		)
		const desktopImageInput = ref(null)
		const mobileImageInput = ref(null)
		const pageList = ref([
			{
				text: 'Home page',
				value: 'Homepage'
			},
			{
				text: 'Video page',
				value: 'Videopage'
			}
		])
		// const positionList = ref([
		// {
		// label: 'Desktop',
		// value: 'Desktop'
		// },
		// {
		// label: 'Mobile',
		// value: 'Mobile'
		// }
		// ])
		const onSubmit = async() => {
			const isValid = await validateAll()
			if (!isValid) return
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					//
					const desktopResponse = await uploadDesktopImage(values.value)
					const mobileResponse = await uploadMobileImage(values.value)
					console.log(desktopResponse)
					let m = setInterval(async () => {
						let imgDesktopPath = await checkFile(desktopResponse.message.data._id)
						let imgMobilePath = await checkFile(mobileResponse.message.data._id)
						if (imgDesktopPath.message.data.path && imgMobilePath.message.data.path) {
							clearInterval(m)
							let advertoria = values.value
							const { name, page, url } = advertoria
							const paramsMobile= {
								name,
								url,
								image: imgMobilePath.message.data.path,
								ratioW: 250,
								ratioH: 250,
								position: 'Mobile',
								page: page
							}
							const params = {
								name,
								url,
								image: imgDesktopPath.message.data.path,
								ratioW: 970,
								ratioH: 90,
								position: 'Desktop',
								page: page
							}
							const mobileResponse =  await createAdvertoria(paramsMobile)
							const desktopResponse =  await createAdvertoria(params)
							if (desktopResponse.status === 1000 && mobileResponse.status === 1000) {
								Swal.fire({
									title:'Upload Success !!',
									icon: 'success',
									confirmButtonText: 'Ok',
									confirmButtonColor: '#BA3B1A'
								})
								values.value = {
									id: null,
									desktopImageFile: null,
									mobileImageFile: null,
									name: '',
									desktopImage: '',
									mobileImage: '',
									ratioW: '',
									ratioH: '',
									url: '',
									position: '',
									page: '',
									isLoading: false
								}
							} else {
								Swal.fire({
									title:'Upload fail !!',
									icon: 'error',
									confirmButtonText: 'Ok',
									confirmButtonColor: '#BA3B1A'
								})
							}
						}
					}, 2000)
				}
			})
		}
		const uploadDesktopImage = async (advertoria) => {
			const { status, message: { data } } = await uploadAdvertiseImage({file: advertoria.desktopImageFile})
			return { status, message: { data } }
		}
		const uploadMobileImage = async (advertoria) => {
			const { status, message: { data } } = await uploadAdvertiseImage({file: advertoria.mobileImageFile})
			return { status, message: { data } }
		}
		const handlerDesktopImage = (event) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				const image = new Image()
				image.onload = (value) => {
					const height = value.path[0].height
					const width = value.path[0].width
					if (width === 970 && height === 90) {
						values.value.desktopImage = e.target.result
						values.value.desktopImageFile = files
					} else {
						return
					}
				}
				image.src = e.target.result
			}
			reader.readAsDataURL(files)
			event.target.value = ''
		}
		const handlerMobileImage = (event, mode) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				const image = new Image()
				image.onload = (value) => {
					const height = value.path[0].height
					const width = value.path[0].width
					if (width === 250 && height === 250) {
						values.value.mobileImage = e.target.result
						values.value.mobileImageFile = files
					} else {
						return
					}
				}
				image.src = e.target.result
			}
			reader.readAsDataURL(files)
			event.target.value = ''
		}
		const onUploadDesktopImageClick = () => {
			desktopImageInput.value.click()
		}
		const onUploadMobileImageClick = () => {
			mobileImageInput.value.click()
		}
		return {
			// positionList,
			values,
			errors,
			onSubmit,
			desktopImageInput,
			mobileImageInput,
			pageList,
			validate,
			validateAll,
			onUploadDesktopImageClick,
			onUploadMobileImageClick,
			handlerMobileImage,
			handlerDesktopImage
		}
	}
})
</script>
<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
