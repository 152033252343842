<template>
	<div class=" bg-phBlack w-full h-full">
		<div
			class=" text-phAdminGrey text-2xl pt-6 px-6 flex justify-between"
		>
			<h1>Upload Highlight</h1>
			<PhButton
				class="w-36 text-base text-white"
				@click="onSubmitClick"
			>
				Submit Highlight
			</PhButton>
		</div>
		<hr class="w-full h-1 mt-6 border-phAdminGrey">
		<div class="flex">
			<div class=" w-1/2 mt-0 pl-6">
				<div class="mt-6">
					<div class=" flex flex-col w-full">
						<label class=" text-phAdminGrey">Title</label>
						<PhInput
							v-model="payload.title"
							class="w-full mt-3"
							:error="errors.title"
							@validate="validate('title')"
						/>
					</div>
				</div>
				<div class="mt-6">
					<div class=" flex flex-col w-full">
						<label class=" text-phAdminGrey">Link URL</label>
						<PhInput
							v-model="payload.link"
							class="w-full mt-3"
							:error="errors.link"
							@validate="validate('link')"
						/>
					</div>
				</div>
				<div class="mt-6">
					<div class=" flex flex-col w-full">
						<label class=" text-phAdminGrey">Description</label>
						<PhTextArea
							v-model="payload.description"
							class="w-full mt-3 h-44"
							:error="errors.description"
							@validate="validate('description')"
						/>
					</div>
				</div>
			</div>
			<div class=" w-1/2 p-6">
				<!-- video Upload zone -->
				<label class="text-phAdminGrey">ชื่อไฟล์</label>
				<PhInput
					v-model="payload.path"
					class="w-full mt-3 mb-6"
					:error="errors.path"
					@validate="validate('path')"
				/>
				<div class=" mt-6">
					<div class=" flex flex-col w-full">
						<label class=" min-w-20 text-phAdminGrey">Preview Image</label>
						<div
							v-if="!isInitalPage && !previewImage"
							class="error-message"
						>
							Please select preview image
						</div>
						<input
							ref="previewImageInput"
							type="file"
							class=" hidden"
							accept="image/*"
							@change="handlePreviewImage"
						>
						<div
							class=" w-full border border-phAdminGrey border-dashed rounded text-center p-10 cursor-pointer hover:border-white"
							@click="onUploadPreviewImageClick"
						>
							<div
								v-if="!previewImage"
							>
								Upload preview Image
							</div>
							<img
								v-if="previewImage"
								:src="previewImage"
								alt=""
								class=" object-contain"
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="progress.show"
			class="dialog"
		>
			<div class="content">
				<p
					v-if="progress.wait"
					class="progress-label my-1"
				>
					{{ progress.text }} upload... ({{ progress.number }}/3)
				</p>
				<p
					v-if="!progress.wait"
					class="progress-label my-1"
				>
					กรุณารอสักครู่ (3/3)
				</p>
				<div class="progress-bar">
					<div
						class="progress"
						:style="{'width' : `${progress.percent}%`}"
					/>
					<span class="percent">{{ progress.percent }} %</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { getAllTag } from '@/api/admin/tag.api'
import { getAllCategories } from '@/api/admin/category.api'
import { uploadVideoPreviewImage, uploadVideo, createMovie, updateMovie, getMovieById, checkFile } from '@/api/admin/highlight.api'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import { useRoute } from 'vue-router'
import useUploadFile from '@/use/useUploadFile.ts'
import { getUrl } from '@/api/admin/config.api'

dayjs.extend(duration)

export default defineComponent({
	setup() {

		const route = useRoute()

		const baseUrl = ref('')

		const previewImageInput = ref(null)
		const previewImage = ref(null)
		const videoPreview = ref(null)
		const waitChunkPreviewVideoPercent = ref(0) 
		const videoResolution = ref(0)
		const videoDuration = ref('')
		const videoTag = ref(null)
		const uploadVideoInput = ref(null)

		// InitialData
		const tags = ref([])
		const categories = ref([])
		const isInitalPage = ref(true)
		const progress = reactive({
			show: false,
			percent: 0,
			number: 1,
			text: 'Preview image',
			wait: true
		})

		const uploadPayload = reactive({
			image: null,
			video: null,
			videoDuration: computed(() => videoDuration.value),
			videoResolution: computed(() => videoResolution.value),
			selectedTags: [],
			selectedCategories: []
		})

		const { validate, validateAll, errors, values: payload } = useValidation(
			{
				title: yup.string().required('กรุณากรอกชื่อหนัง'),
				description: yup.string().required('กรุณากรอกรายละเอียดหนัง'),
				path: yup.string().required('กรุณากรอกชื่อไฟล์ที่ต้องการ'),
			},
			{
				title: '',
				description: '',
				path: ''
			}
		)

		watch(
			() => route.query,
			() => {
				payload.value.title = ''
				payload.value.description = ''
				payload.value.link = ''
				payload.value.path = ''
				uploadPayload.selectedCategories = []
				uploadPayload.selectedTags = []
				uploadPayload.video = null
				uploadPayload.image = null
				previewImage.value = null
				videoPreview.value = null
			},
		)

		const isSelectedVideo = computed(() => {
			return waitChunkPreviewVideoPercent.value > 0 && waitChunkPreviewVideoPercent.value < 100
		})

		onMounted(() => {
			getAllTag({ page: 1 }).then(response => {
				tags.value = response.message.data.tags
			})
			getAllCategories({ page: 1 }).then(response => categories.value = response.message.data.categories)

			getUrl({ name: 'urlHighlight' }).then(response => {
				baseUrl.value = response.message.data.value
				if (route.query.updateVideoId) {
					getInitialMovieData()
				}
			})
		})

		const getInitialMovieData = async () => {
			const { message: { data: videoDetail } } = await getMovieById(route.query.updateVideoId)
			payload.value.title = videoDetail.title
			payload.value.description = videoDetail.description
			payload.value.link = videoDetail.link
			payload.value.path = videoDetail.path.replace(baseUrl.value + '/', '').replace('/playlist.m3u8', '')
			uploadPayload.selectedCategories = videoDetail.categories
			uploadPayload.selectedTags = videoDetail.tags
			previewImage.value = videoDetail.preview
			// videoPreview.value = videoDetail.path
			// isInitalPage.value = true
		}

		const isTagSelected = (tag) => {
			// console.log(uploadPayload.selectedTags.includes(tag))
			return uploadPayload.selectedTags.includes(tag)
		}


		const handlePreviewImage = (event) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				previewImage.value = e.target.result
			}

			uploadPayload.image = files

			reader.readAsDataURL(files)
			event.target.value = ''
		}

		const handleVideoChange = (event) => {
			const files = event.target.files[0]
			if (!files) return

			uploadPayload.video = files

			videoPreview.value = null // clear
			waitChunkPreviewVideoPercent.value = 0 // clear

			useUploadFile(
				files,
				(chunkRes) => {
					waitChunkPreviewVideoPercent.value = chunkRes.percent
				},
				() => { // on Complete callback
					nextTick(() => {
						videoTag.value.onloadedmetadata = (value) => {
							console.log('on load video data -> ', value)
							if (value.srcElement.videoHeight <= 2160) {
								const resolutionList = [360, 480, 720, 1080, 2160]
								videoResolution.value = resolutionList.find(res => value.srcElement.videoHeight <= res)
							} else {
								videoResolution.value = value.srcElement.videoHeight
							}
							videoDuration.value = videoTag.value.duration
							// console.log('video quality ->', videoTag.value.getVideoPlaybackQuality())
							const time = dayjs.duration(videoTag.value.duration * 1000)
							const durationFormat = dayjs.duration({
								seconds: time.seconds(),
								minutes: time.minutes(),
								hours: time.hours()
							}).format('HH:mm:ss')

							videoDuration.value = durationFormat

						}
					})
					const blobURL = window.URL.createObjectURL(files)
					videoPreview.value = blobURL //on this line, the exception is thrown
				}
			)
		}

		const onUploadPreviewImageClick = () => {
			previewImageInput.value.click()
		}

		const handleRef = ref => {
			videoTag.value = ref
		}
		const onSubmitClick = () => {
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					isInitalPage.value = false
					const isTitleAndDesValid = await validateAll()
					const isFormValid = !isTitleAndDesValid || !previewImage.value
					if (isFormValid) return
					// Default Old path
					let imagePreview = null
					if (uploadPayload.image) {
						imagePreview = await uploadVideoPreviewImage({ file: uploadPayload.image, onUploadProgress: uploadImageProgress })
					}
					console.log('IMAGE :: ', uploadPayload)
					// // Default Old Path
					// let videoUrl = null
					// if (uploadPayload.video) {
					// 	videoUrl = await uploadVideo({ file: uploadPayload.video, onUploadProgress: uploadVideoProgress })
					// }

					let params = {
						categories: uploadPayload.selectedCategories,
						tags: uploadPayload.selectedTags,
						title: payload.value.title,
						description: payload.value.description,
						path: baseUrl.value + '/' + payload.value.path + '/playlist.m3u8',
						duration: covertTimeToSecond(videoDuration.value),
						resolution: videoResolution.value,
						watched: 0,
						preview: imagePreview && imagePreview.message.data.Location,
						link: payload.value.link
					}
					const videoId = route.query.updateVideoId
					let response
					if (videoId) {
						response = await updateMovie({ id: videoId, ...params })
					} else {
						response = await createMovie(params)
					}
					// console.log('Create:', videoId)
					if (response.status === 1000) {
						let m = setInterval(async () => {
							let imgPath = await checkFile(imagePreview.message.data._id)
							if (imgPath.message.data.path) {
								clearInterval(m)
								params.preview = imgPath.message.data.path
								response = await updateMovie({ id: response.message.data._id, ...params })
								progress.wait = false
								if (!payload.value.path) {
									payload.value.title = ''
									payload.value.description = ''
									payload.value.path = ''
									uploadPayload.selectedCategories = []
									uploadPayload.selectedTags = []
									uploadPayload.video = null
									uploadPayload.image = null
									previewImage.value = null
								}
								Swal.fire({
									title: 'บันทึกไฟล์สำเร็จ',
									icon: 'success',
									confirmButtonText: 'ยืนยัน',
									confirmButtonColor: '#BA3B1A',
								})
								progress.show = false
								progress.wait = true
								isInitalPage.value = true
							}
						}, 5000)
					} else {
						Swal.fire({
							title:'Upload Failed !!',
							icon: 'error',
							confirmButtonText: 'Ok',
							confirmButtonColor: '#BA3B1A'
						})
					}
				}
			})
		}

		const covertTimeToSecond = (time) => {
			const timeList = time.split(':')
			return (+timeList[0]) * 60 * 60 + (+timeList[1]) * 60 + (+timeList[2])
		}

		const uploadImageProgress = (progressEvent) => {
			const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
			progress.show = true
			if (totalLength !== null) {
				progress.number = 1
				progress.text = 'Preview image'
				progress.percent = Math.round((progressEvent.loaded * 100) / totalLength )
				// if (progress.percent === 100) {
				// 	progress.show = false
				// }
			}
		}
		const uploadVideoProgress = (progressEvent) => {
			const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
			progress.show = true
			if (totalLength !== null) {
				progress.number = 2
				progress.text = 'Video'
				progress.percent = Math.round((progressEvent.loaded * 100) / totalLength )
				// if (progress.percent === 100) {
				// 	progress.show = false
				// }
			} else {
				progress.show = false
			}
		}

		const onUploadClick = () => {
			uploadVideoInput.value.click()
		}

		const onSelected = (value) => {
			// console.log('valie, valiue', value)
			if (uploadPayload.selectedTags.includes(value)) {
				uploadPayload.selectedTags = uploadPayload.selectedTags.filter(tag => tag !== value)
			} else {
				uploadPayload.selectedTags.push(value)
			}
		}

		return {
			handlePreviewImage,
			onUploadPreviewImageClick,
			previewImageInput,
			previewImage,
			videoPreview,
			videoResolution,
			videoDuration,
			handleVideoChange,
			handleRef,
			onSubmitClick,
			onUploadClick,
			uploadVideoInput,
			onSelected,
			isTagSelected,
			uploadPayload,
			tags,
			categories,
			validate,
			validateAll,
			errors,
			payload,
			isInitalPage,
			progress,
			waitChunkPreviewVideoPercent,
			isSelectedVideo
		}
	},
})
</script>
<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
<style lang="scss" scoped>
.dialog {
	display: flex;
	position: fixed;
	z-index: 1;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
	align-items: center;
	.content {
		background-color: #000;
		margin: auto;
		padding: 20px;
		border: 1px solid #888;
		border-radius: 4px;
		min-width: 500px;
		min-height: 100px;
		display: flex;
        flex-direction: column;
		align-items: center;
	}
}
.progress-label {
    text-align: center;
    width: 100%;
    color: #FFF;
}
.progress-bar {
    position: relative;
	height: 22px;
	width: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border: 1px solid #808080;
	border-radius: 4px;
    transition: all .2s ease;
	.progress {
		height: 20px;
        border-radius: 4px;
		background-color: #E44918;
	}
	.percent {
        position: absolute;
        left: 50%;
        top: 0;
	}
}
.error-message {
    color: #E44918;
}
</style>
