<template>
	<div class="relative">
		<input
			:type="type"
			:placeholder="placeholder"
			v-model="search"
			class=" bg-phBlack border rounded h-11 pl-4 w-9/12 outline-none"
			:class="`${!!error ? ' border-phRed' : 'border-phAdminGrey'}`"
			@input="handleEmit"
			@blur="handleValidate"
		>
		<button
			class="ml-2 w-2/12 h-11 rounded outline-none hover:bg-red-700 bg-phRed"
			@click="addOption"
		>
			<i class="icon-Pluse mr-1"></i>
			Add
		</button>
		<div
			class="option-input absolute top-full left-0 w-9/12 p-2 bg-phMainBackground flex flex-wrap"
			:class="!showOption ? 'opacity-0' : 'opacity-100'"
			style="z-index: 1"
		>
			<div
				v-for="(item, index) in getOptions"
				:key="index"
				class="w-max p-2 whitespace-nowrap cursor-pointer hover:bg-phBlack border border-transparent hover:border-red-500 rounded"
				@click="selectOption(item)"
			>
				{{item}}
			</div>
		</div>
	</div>
</template>

<script>
import { computed, ref } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'PhInputDropdown',
	props: {
		type: {
			type: String,
			default: 'text'
		},
		placeholder: {
			type: String,
			default: null
		},
		options: {
			type: Array,
			default: () => [
				'Saeko Matsushita',
				'Marina Shiraishi',
				'Ayumi Miura',
				'Yuko Shiraki',
				'Aki Sasaki',
				'Maki Hojo',
				'Yumi Kazama',
				'Yuka Oshima',
				'Chisato Shoda',
				'Hikari Kisaki'
			]
		}
	},
	setup(props, { emit }) {
		let showOption = ref(false)
		let search = ref('')

		const handleEmit = (event) => {
			search.value = event.target.value
			emit('emitKeyup', event.target.value)   
		}

		const findOptions = () => {
			if(search.value){
				let find = props.options.filter(op => {
					return op.toLowerCase().includes(search.value.toLowerCase())
				})
				if(find.length > 0){
					showOption.value = true
				}else{
					showOption.value = false
				}
				return find
			}else{
				showOption.value = false
				return []
			}
		}
		const getOptions = computed(() => {
			return findOptions()	
		})

		const selectOption = (value) => {
			search.value = ''
			emit('optionSet', value)
		}

		const addOption = () => {
			emit('newOption', search.value)
			search.value = ''
		}

		return {
			showOption,
			search,

			handleEmit,
			getOptions,
			selectOption,
			addOption
		}
	},
})
</script>
