import { RouteLocationNormalized } from 'vue-router'
import store from '@/store'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
	// return '/login'
	if (to.path !== '/login') {
		const isAlreadyAuth = (store.state as any).authStore.token
		if (!isAlreadyAuth) {
			console.log('Not auth')
			return false
		}
	}
	return true
}