<template>
	<div>
		<h1>Video Page</h1>
		<PhTable
			:data="tableData.data"
			:headers="tableData.headers"
			:offset-list="tableData.countList"
			:status-list="tableData.statusList"
			:offset="tableData.offset"
			class="movie-table"
			@onRowClick="onRowClick"
		>
			<template #content-memberId="{ item }">
				<PhButton @click="onDeleteRow(item)">
					Delete
				</PhButton>
			</template>
		</PhTable>
	</div>
</template>

<script>
import { defineComponent, nextTick, onMounted, reactive } from 'vue'
import PhTable from '@/components/global/PhTable.vue'
import { getAllMember, deleteMember } from '@/api/admin/member.api'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export default defineComponent({
	components: { PhTable },
	setup () {
		const router = useRouter()
		const tableData = reactive({
			data: [],
			headers: [
				{
					label: 'Display Name',
					key: 'displayName'
				},
				{
					label: 'User Name',
					key: 'username'
				},
				{
					label: 'Gender',
					key: 'gender'
				},
				{
					label: 'Delete',
					key: 'memberId'
				}
			],
			countList: [
				{
					label: '10',
					value: 10
				},
				{
					label: '25',
					value: 25
				},
				{
					label: '50',
					value: 50
				}
			],
			statusList: [
				{
					label: 'All Status',
					status: 0
				},
				{
					label: 'Actor',
					status: 1
				},
				{
					label: 'customer',
					status: 2
				}
			]
		})
		onMounted(() => {
			nextTick(() => {
				getAllMember({ page: 1 }).then(memberResponse => {
					if (memberResponse.status === 1000) {
						tableData.data = memberResponse.message.data.users.map(member => {
							return {
								...member,
								memberId: member._id
							}
						})
					}
				})
			})
		})
		// const onEditStatus = () => {
		// console.log('Edit status')
		// }
		const onDeleteRow = (item) => {
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					try {
						await deleteMember({ id: item }).then((response => {
							if (response.status === 1000) {
								const tableList = tableData.data
								tableData.data = tableList.filter(item => item._id !== response.message.data)
							} else {
								Swal.fire({
									title: response.message.error,
									icon: 'error',
									confirmButtonText: 'Ok',
									confirmButtonColor: '#BA3B1A'
								})
							}
						}))
					} catch (error) {
						console.error(error)
					}
				}
			})
		}

		const onRowClick = (item) => {
			return router.push({ path: '/user/add', query: { updateMemberId: item._id } })
		}

		return {
			tableData,
			// onEditStatus,
			onDeleteRow,
			onRowClick
		}
	}
})
</script>
<style lang="scss" scoped>
::v-deep.movie-table {
    .header-preview {
        text-align: center;
    }
    .header-categories {
        width: 300px;
    }
    .content-categories {
        width: 300px;
    }
}
</style>

<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
