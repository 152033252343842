declare global {
    interface Window {
        configs: any;
    }
}

const getEnv = (key: string): any => {
	return window.configs
		? JSON.stringify(window.configs)
		: process.env[key]
}

export default getEnv