<template>
	<div class="w-full h-full bg-phBlack flex flex-col items-center justify-center">
		<div>
			<img
				:src="require('@/assets/ph-logo.png')"
				class=" mb-12"
				alt=""
			>
		</div>
		<form
			class="flex flex-col w-1/4 p-6 bg-black rounded"
			@submit.prevent="submitLogin"
		>
			<h1 class=" text-phAdminGrey text-2xl">
				Login
			</h1>
			<div
				v-if="errorMessage"
				class="mt-5 error-message"
			>
				{{ errorMessage }}
			</div>
			<div class=" text-phAdminGrey mt-5">
				<label for="username">Username</label>
				<PhInput
					v-model="payload.username"
					class="mt-2"
					:error="errors.username"
					@validate="validate('username')"
					@click="onClearValidate"
				/>
			</div>
			<div class=" text-phAdminGrey mt-5">
				<label for="username">Password</label>
				<PhInput
					v-model="payload.password"
					:error="errors.password"
					class="mt-2"
					type="password"
					@validate="validate('password')"
					@click="onClearValidate"
				/>
			</div>
			<PhButton
				class="text-white mt-10"
				:loading="isLogin"
			>
				Login
			</PhButton>
		</form>
	</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import { login } from '@/api/admin/auth.api'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'Login',
	setup() {
		//
		const isLogin = ref(false)
		const errorMessage = ref(null)
		const { validate, validateAll, errors, values: payload } = useValidation(
			{
				username: yup.string().required('Please enter username.'),
				password: yup.string().required('Please enter password').min(4, 'Please enter minimum password')
			},
			{
				username: '',
				password: ''
			}
		)
		const router = useRouter()
		const store = useStore()

		const submitLogin = async () => {
			try {
				const isFormValid = await validateAll()
				if (!isFormValid) return
				isLogin.value = true
				
				const loginResponse = await login(payload.value)
				if (loginResponse.status === 1000) {
					store.commit('authStore/updateAuthToken', loginResponse.message.data)
					await router.push('/dashboard')
					isLogin.value = false
				} else if (loginResponse.status === 5000) {
					errorMessage.value = loginResponse.message.error
					isLogin.value = false
				} else {
					isLogin.value = false
				}
			} catch (error) {
				console.log(error)
				isLogin.value = false
			}

		}

		const onClearValidate = () => {
			errorMessage.value = ''
		}

		return {
			submitLogin,
			validate,
			validateAll,
			errors,
			payload,
			isLogin,
			errorMessage,
			onClearValidate
		}
	},
})
</script>
<style lang="scss" scoped>
.error-message {
	border-radius: 2px;
	border: 1px solid #E44918;
	background-color: #E44918;
	color: #fff;
	text-align: center;
	padding: 0.5em 0;
}
</style>
