<template>
	<!-- Navbar Zone -->
	<div class=" fixed top-0 w-full h-16 bg-phBlack flex justify-between items-center">
		<div class=" w-28 h-auto ml-8">
			<img
				:src="require('@/assets/ph-logo.png')"
				loading="lazy"
			>
		</div>
		<div class="flex items-center mr-6 relative">
			<p class="text-white">
				Admin
			</p>
			<div
				class="cursor-pointer ml-4 rounded-full w-10 h-10 bg-phRed flex items-center justify-center text-white"
				@click="onToggleMenu"
			>
				<img src="@/assets/icon/user-inactive.svg">
			</div>
			<div
				v-if="toggleMenu"
				class="absolute bottom-0 left-0 transform translate-y-14 -translate-x-5"
			>
				<PhButton
					type="button"
					class="w-28 ml-4 h-11 text-white"
					@click="onLogout"
				>
					Log out
				</PhButton>
			</div>
		</div>
	</div>
	<!-- End Navbar Zone -->
	<div class=" flex pt-16 min-h-full">
		<!-- Sidebar zone -->
		<div class=" w-60 min-h-full bg-phSidebar">
			<div
				v-for="(menu, i) in menuList"
				:key="`menu-${i}`"
				class=" mt-3"
			>
				<div
					style="color: #4f4f4f"
					class="ml-3 flex items-center"
				>
					{{ menu.title }}
				</div>
				<div
					v-for="(firstChild, idx) in menu.child"
					:key="`child-${idx}`"
					class=""
				>
					<RouterLink
						v-slot="{ navigate, isActive, isExactActive }"
						:to="firstChild.path"
						custom
					>
						<div
							class="text-phAdminGrey flex items-center cursor-pointer hover:bg-black h-12"
							:class="{
								'border-r-4': isExactActive,
								'border-phRed': isExactActive,
								'bg-black': isExactActive || isActive,
							}"
							@click="canNavigate(navigate, firstChild)"
						>
							<p class=" ml-4 flex items-center">
								<i :class="[firstChild.icon, { 'active': isActive }]" />
								<span
									class="mt-1 ml-2"
									:class="{ 'text-phRed': isActive }"
								>
									{{ firstChild.title }}
								</span>
							</p>
						</div>
					</RouterLink>
					<div
						v-if="firstChild.children && firstChild.children.length"
						class=" text-phAdminGrey"
					>
						<RouterLink
							v-for="(second, index) in firstChild.children"
							:key="`second-child-${index}`"
							v-slot="{ navigate, isExactActive }"
							:to="second.path"
							custom
						>
							<div
								class=" flex items-center h-12 cursor-pointer hover:bg-black pl-5"
								:class="{
									'border-r-4': isExactActive,
									'border-phRed': isExactActive,
									'bg-black': isExactActive,
									'text-phRed': isExactActive
								}"
								@click="navigate"
							>
								<i :class="[second.icon, { 'active': isExactActive }]" />
								<p class=" ml-4">
									{{ second.title }}
								</p>
							</div>
						</RouterLink>
					</div>
				</div>
			</div>
		</div>
		<!-- End Sidebar zone -->
		<div class=" bg-phMainBackground flex-1 text-white p-4">
			<router-view />
		</div>
	</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
	setup() {
		const toggleMenu = ref(false)
		const router = useRouter()
		const store = useStore()
		const menuList = ref([
			{
				title: 'Main',
				child: [
					{
						title: 'Dashboard',
						icon: 'icon-dashboard-inactive',
						path: '/dashboard'
					},
					{
						title: 'User',
						icon: 'icon-user-inactive',
						path: '/user',
						children: [
							{
								title: 'Add new',
								icon: 'icon-Pluse',
								path: '/user/add',
							}
						]
					},
				]
			},
			{
				title: 'Management',
				child: [
					{
						title: 'Video',
						icon: 'icon-video-camera-inactive',
						path: '/videos',
						children: [
							{
								title: 'Upload',
								icon: 'icon-cloud-computing-inactive',
								path: '/videos/upload',
							}
						]
					},
					{
						title: 'Highlight',
						icon: 'icon-video-camera-inactive',
						path: '/highlight',
						children: [
							{
								title: 'Upload',
								icon: 'icon-cloud-computing-inactive',
								path: '/highlight/upload',
							}
						]
					},
				]
			},
			{
				title: '',
				child: [
					{
						title: 'Tag',
						icon: 'icon-price-tag-inactive',
						path: '/tag'
					},
					{
						title: 'Category',
						icon: 'icon-grid-inactive',
						path: '/category'
					},
					{
						title: 'Advertoria',
						icon: 'icon-grid-inactive',
						path: '/advertoria',
						children: [
							{
								title: 'Add banner large Leaderboard',
								icon: 'icon-Pluse',
								path: '/advertoria/large-leader-board'
							},
							{
								title: 'Rectangle',
								icon: 'icon-Pluse',
								path: '/advertoria/rectangle'
							}
						]
					}
				]
			}
		])
		const canNavigate = (navigate, firstChild) => {
			if (!firstChild.child || !firstChild.child.length) {
				return navigate()
			}
		}
		const onToggleMenu = () => {
			toggleMenu.value = !toggleMenu.value
		}
		const onLogout = async() => {
			store.commit('authStore/updateAuthToken', null)
			await router.push('/login')
		}
		return { menuList, canNavigate, toggleMenu, onToggleMenu, onLogout }
	},
})
</script>
<style lang="scss" scoped>
.active:before {
	color: red;
}
</style>
