<template>
	<div>
		<input
			:type="type"
			:placeholder="placeholder"
			:value="modelValue"
			class=" bg-phBlack border rounded h-11 pl-4 w-full outline-none"
			:class="`${!!error ? ' border-phRed' : 'border-phAdminGrey'}`"
			@input="handleEmit"
			@blur="handleValidate"
		>
		<p
			v-if="!!error"
			:class="`${!!error ? ' text-phRed' : ''}`"
		>
			{{ error }}
		</p>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'PhInput',
	props: {
		modelValue: {
			type: [String, Number, Object],
			default: null
		},
		type: {
			type: String,
			default: 'text'
		},
		placeholder: {
			type: String,
			default: null
		},
		error: {
			type: null,
			default: null
		},
	},
	emits: ['update:modelValue', 'validate', 'emitKeyup'],
	setup(_, { emit }) {
		const handleEmit = (event) => {
			emit('update:modelValue', event.target.value)
			emit('emitKeyup', event.target.value)
			handleValidate()      
		}

		const handleValidate = () => {
			emit('validate')
		}

		return { handleEmit, handleValidate }
	},
})
</script>
