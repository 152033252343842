export default function parseFile(file: any, callback: any, onCompleteCallback: any): void {
	const fileSize   = file.size
	const chunkSize  = 200000000 // bytes
	let offset     = 0
	let chunkReaderBlock: any = null

	const readEventHandler = (evt: any) => {
		if (evt.target.error == null) {
			offset += evt.target.result.length
			callback({
				result: evt.target.result,
				percent: offset <= fileSize ? (offset/fileSize) * 100 : 100 // calculate percent
			}) // callback for handling read chunk
		} else {
			console.log('Read error: ' + evt.target.error)
			return
		}
		if (offset >= fileSize) {
			console.log('Done reading file')
			onCompleteCallback()
			return
		}

		// of to the next chunk
		chunkReaderBlock(offset, chunkSize, file)
	}

	chunkReaderBlock = (_offset: any, length: any, _file: any) => {
		const r = new FileReader()
		console.log('Yeahhhh')
		const blob = _file.slice(_offset, length + _offset)
		r.onload = readEventHandler
		r.readAsDataURL(blob)
	}

	// now let's start the read with the first block
	chunkReaderBlock(offset, chunkSize, file)
}