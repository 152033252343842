import { Ref, ref } from 'vue'
import * as yup from 'yup'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useValidation = (initialRules: any, initialValue: unknown) => {
	const values = ref(initialValue)
	const rules = yup.object().shape(initialRules)
	const errors: Ref = ref({})
	const validate = (field: string) => {
		rules
			.validateAt(field, values.value)
			.then(() => (errors.value[field] = null))
			.catch((error) => {
				errors.value[field] = error.message
			})
	}
	const validateAll = () => {
		return rules
			.validate(values.value, { abortEarly: false })
			.then(() => {
				errors.value = {} // clear error
				return true
			})
			.catch((validateErrors) => {
				validateErrors.inner.forEach((error: { path: string | number; message: string }) => {
					errors.value[error.path] = error.message
				})
				return false
			})
	}

	return {
		rules,
		values,
		errors,
		validate,
		validateAll
	}
}

export default useValidation
