<template>
	<div class="w-6 h-6 border-4 border-white rounded-full loader" />
</template>

<style lang="scss" scoped>
	@keyframes loader-rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.loader {
		border-right-color: transparent !important;
		animation: loader-rotate 1s linear infinite;
	}
</style>