<template>
	<!-- This example requires Tailwind CSS v2.0+ -->
	<div
		v-if="modelValue"
		class="fixed z-10 inset-0 overflow-y-auto"
	>
		<div
			class="fixed inset-0 bg-phGrey bg-opacity-25 transition-opacity"
			@click="closeModal"
		/>

		<slot />
	</div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
	props: {
		modelValue: {
			type: Boolean,
			default: true
		}
	},
	emits: ['update:modelValue'],
	setup (_ ,{ emit }) {
		//
		const closeModal = () => {
			emit('update:modelValue', false)
		}
		return {
			closeModal
		}
	}
})
</script>
