<template>
	<div class="m-5 ">
		<header>
			<h1 class="font-bold text-2xl text-phAdminGrey border-b-2 pb-3 border-phGray">
				Advertoria
			</h1>
		</header>
		<main class="flex mt-5">
			<div class="pt-12 mr-10">
				<img
					src="@/assets/rectangle.svg"
					width="200"
				>
			</div>
			<form
				class="flex-1 mt-10"
				@submit.prevent="onSubmit"
			>
				<h3 class="font-bold text-phAdminGrey mb-2">
					Name
				</h3>
				<PhInput
					v-model="values.name"
					class="w-2/3 mb-5"
					placeholder="Please enter advertoria name"
					:error="errors.name"
					@validate="validate('name')"
				/>
				<h3 class="font-bold text-phAdminGrey mb-2">
					URL
				</h3>
				<PhInput
					v-model="values.url"
					class="w-2/3 mb-5"
					placeholder="Please enter advertoria url"
					:error="errors.url"
					@validate="validate('url')"
				/>
				<h3 class="font-bold text-phAdminGrey">
					Rectangle
				</h3>
				<p class="text-phGray mb-4">
					This image will display all device
				</p>
				<div
					v-if="values.desktopImage"
					class="w-2/3 mb-5 text-center"
				>
					<img
						:src="values.desktopImage"
						style="object-fit: content;"
					>
				</div>
				<div
					v-else
					class="w-2/3 mb-5 text-center border-4 border-phGrey border-dotted rounded-md p-8 text-phGray"
				>
					Recommend size 300x200
				</div>
				<p
					v-if="!values.desktopImage && errors.desktopImage"
					class="text-phRed mt-2"
				>
					{{ errors.desktopImage }}
				</p>
				<input
					ref="desktopImageInput"
					type="file"
					class="hidden"
					accept="image/*"
					@change="handlerDesktopImage($event)"
					@validate="validate('desktopImageFile')"
				>
				<PhButton
					class="mb-5 font-bold"
					type="button"
					@click="onUploadDesktopImageClick"
				>
					Browse
				</PhButton>
				<h3 class="font-bold text-phAdminGrey mb-5">
					Page
				</h3>
				<PhSelect
					v-model="values.page"
					:items="pageList"
					:error="errors.page"
					placeholder="Plase select page"
					class="w-2/3"
					@validate="validate('page')"
				/>
				<PhButton
					class="mt-10 w-2/3"
					type="submit"
				>
					Add
				</PhButton>
			</form>
		</main>
	</div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import { checkFile } from '@/api/admin/movies.api'
import PhSelect from '@/components/global/PhSelect.vue'
import { uploadAdvertiseImage, createAdvertoria } from '@/api/admin/advertoria.api'
import * as yup from 'yup'
import useValidation from '@/use/useValidate'
import Swal from 'sweetalert2'

export default defineComponent({
	components: {
		PhSelect
	},
	setup() {
		const { validate, validateAll, errors, values } = useValidation(
			{
				name: yup.string().required('Please enter Advertoria Name'),
				url: yup.string().required('Please enter Advertoria Url'),
				desktopImage: yup.string().required('Please select image'),
				page: yup.string().required('Please select page')
			},
			{
				id: null,
				desktopImageFile: null,
				name: '',
				url: '',
				desktopImage: '',
				ratioW: '',
				ratioH: '',
				position: '',
				page: '',
				isLoading: false
			}
		)
		const desktopImageInput = ref(null)
		const pageList = ref([
			{
				text: 'Home page',
				value: 'Homepage'
			},
			{
				text: 'Video page',
				value: 'Videopage'
			}
		])

		const handlerDesktopImage = (event) => {
			const files = event.target.files[0]
			if (!files) return

			const reader = new FileReader()
			reader.onload = (e) => {
				const image = new Image()
				image.onload = (value) => {
					const height = value.path[0].height
					const width = value.path[0].width
					if (width === 300 && height === 200) {
						values.value.desktopImage = e.target.result
						values.value.desktopImageFile = files
					} else {
						return
					}
				}
				image.src = e.target.result
			}
			reader.readAsDataURL(files)
			event.target.value = ''
		}
		const onSubmit = async() => {
			const isValid = await validateAll()
			if (!isValid) return
			Swal.fire({
				title: 'Are you sure?',
				icon: 'warning',
				showDenyButton: true,
				denyButtonText: 'Cancel',
				confirmButtonText: 'Comfirm',
				confirmButtonColor: '#BA3B1A',
				denyButtonColor: '#FFF'
			}).then(async(result) => {
				if (result.isConfirmed) {
					try {
						const { status, message: { data } } = await uploadAdvertiseImage({file: values.value.desktopImageFile})
						let m = setInterval(async () => {
							let imgPath = await checkFile(data._id)
							if (imgPath.message.data.path) {
								clearInterval(m)
								const { name, position, url, page } = values.value
								const params = {
									name,
									url,
									image: imgPath.message.data.path,
									ratioW: 300,
									ratioH: 200,
									position: 'Desktop',
									page
								}
								const response =  await createAdvertoria(params)
								if (response.status === 1000) {
									Swal.fire({
										title:'Upload Success !!',
										icon: 'success',
										confirmButtonText: 'Ok',
										confirmButtonColor: '#BA3B1A'
									})
									values.value = {
										id: null,
										url: '',
										desktopImageFile: null,
										name: '',
										desktopImage: '',
										ratioW: '',
										ratioH: '',
										position: '',
										page: '',
										isLoading: false
									}
								} else {
									Swal.fire({
										title:'Upload fail !!',
										icon: 'error',
										confirmButtonText: 'Ok',
										confirmButtonColor: '#BA3B1A'
									})
								}
							}
						}, 2000)
					} catch (error) {
						console.error(error)
					}
				}
			})
		}

		const onUploadDesktopImageClick = () => {
			desktopImageInput.value.click()
		}

		return {
			pageList,
			values,
			errors,
			validate,
			validateAll,
			onSubmit,
			onUploadDesktopImageClick,
			handlerDesktopImage,
			desktopImageInput
		}
	}
})
</script>
<style lang="scss">
.swal2-container > .swal2-popup > .swal2-actions > .swal2-deny {
	border: 1px solid #000 !important;
	color: #000 !important;
}
</style>
