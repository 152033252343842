import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

export type TTag = {
    page: number
}

export type FFile = {
    file: File,
    onUploadProgress?: () => unknown
}

export const getAllTag = (params: TTag): Promise<AxiosResponse> =>
	axiosInstance.get('/tags', {
		params
	}).then(response => response.data)

export interface ITagParams {
	id: string,
	name: string,
	image?: string
}

export const editTagById = (params: ITagParams): Promise<AxiosResponse<ITagParams>> =>
	axiosInstance.put('/tags', params).then(response => response.data)

export const addTag = (params: ITagParams): Promise<AxiosResponse<ITagParams>> =>
	axiosInstance.post('/tags', params).then(response => response.data)

export const deleteTagById = (tagId: string): Promise<AxiosResponse> =>
	axiosInstance.delete('/tags', {
		data: { id: tagId }
	}).then(response => response.data)

export const addTagImage = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/tags', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}
