import { AxiosResponse } from 'axios'
import axiosInstance from '../instance'

export type FFile = {
    file: File,
    onUploadProgress: () => unknown
}
export type MMovies = {
    categories: Array<string>,
    tags: Array<string>,
    title: string,
    description: string,
    path: string,
    duration: number,
    resolution: number,
    watched: number,
    preview: string
}

export type PPage = {
    page: number
}

export type IId = {
    id: string
}

export const getAllMovies = (params: PPage): Promise<AxiosResponse> => 
	axiosInstance.get('/movies', {
		params: {
			...params,
		}
	}).then(response => response.data)

export const getMovieById = (movieId: string): Promise<AxiosResponse> =>
	axiosInstance.get(`/movies/${movieId}`).then(response => response.data)

export const deleteMovie = (data: IId): Promise<AxiosResponse> => 
	axiosInstance.delete('/movies', {
		data
	}).then(response => response.data)

export const uploadVideoPreviewImage = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/preview', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}

export const uploadVideo = (params: FFile): Promise<AxiosResponse> => {
	const form = new FormData()
	form.append('file', params.file)
	return axiosInstance.post('/file/videos', form, {
		headers: {
			'Content-type': 'multipart/form-data',
			'Convert-formdata': 'true'
		},
		'onUploadProgress': params.onUploadProgress
	}).then(response => response.data)
}

export const checkFile = (params: string): Promise<AxiosResponse> => {
	return axiosInstance.get('/file/' + params).then(response => response.data)
}

export const createMovie = (params: MMovies): Promise<AxiosResponse> => {
	return axiosInstance.post('/movies', { ...params }).then(response => response.data)
}

interface MMovieEdit extends MMovies {
	id: string
}

export const updateMovie = (params: MMovieEdit): Promise<AxiosResponse> => {
	return axiosInstance.put('/movies', params).then(response => response.data)
}

export const replaceUrl = (data: any): Promise<AxiosResponse> => {
	return axiosInstance.post('/movies/replace', data).then(response => response.data)
}

export const replaceHighlightUrl = (data: any): Promise<AxiosResponse> => {
	return axiosInstance.post('/movies/replace/highlight', data).then(response => response.data)
}