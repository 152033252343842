<template>
	<div
		class="cursor-pointer relative group"
		@click="updateModelValue(value)"
	>
		<div
			:class="`tag relative border p-1 px-5 rounded ${!deleteMode ? 'hover:border-white hover:text-white' : '' } ${isSelected ? 'border-white text-white': 'border-phGrey text-phGrey'}`"
		>
			{{ text }}
			<button
				v-if="deleteMode"
				class="tag-remove absolute rounded text-white bg-red-500 bg-opacity-70 top-0 right-0 h-full"
				@click="removeTag(value)"
			>
				X
			</button>
		</div>
		<slot name="custom" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'PhTag',
	props: {
		to: {
			type: [Object, String],
			default: '#'
		},
		text: {
			type: String,
			default: '-'
		},
		value: {
			type: [String, Object, Array],
			default: null
		},
		modelValue: {
			type: [String, Object, Array],
			default: null
		},
		isSelected: {
			type: Boolean,
			default: false
		},
		deleteMode: {
			type: Boolean,
			default: false
		}
	},
	emits: ['onSelected'],
	setup (_, { emit }) {
		const updateModelValue = (value) => {
			emit('onSelected', value)
		}

		const removeTag = (value) => {
			emit('onRemove', value)
		}

		return { updateModelValue, removeTag }
	}
})
</script>

<style lang="scss" scoped>

.tag-remove{
	opacity: 0;
	padding: 0px 12px;
	border-radius: 50%;
	transition: all .3s;
}


.tag:hover{
	.tag-remove{
		opacity: 1;
	}
}

</style>
