import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/adminGlobal.scss'
import './index.css'

import PhInput from '@/components/global/PhInput.vue'
import PhTextArea from '@/components/global/PhTextArea.vue'
import PhButton from '@/components/global/PhButton.vue'
import PhTag from '@/components/global/PhTag.vue'
import PhModal from '@/components/global/PhModal.vue'

const app = createApp(App)

app.use(store)
app.use(router)

// Mounting Components
app.component('PhInput', PhInput)
app.component('PhTextArea', PhTextArea)
app.component('PhButton', PhButton)
app.component('PhTag', PhTag)
app.component('PhModal', PhModal)

// Mounting App
app.mount('#app')
