<template>
	<button
		:class="getClassBtn"
		@click.stop="onClick"
	>
		<slot v-if="!loading" />
		<Spinner
			v-else
			class="m-auto"
		/>
	</button>
</template>

<script>
import { defineComponent } from 'vue'
import Spinner from '@/components/elements/Spinner'

export default defineComponent({
	components: { Spinner },
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		bgClass: {
			type: String,
			default: 'bg-phRed'
		},
		bgHoverClass: {
			type: String,
			default: 'bg-red-700'
		},
	},
	emits: ['click'],
	setup(props, { emit }) {
		const onClick = () => {
			if (props.loading) return 
			emit('click')
		}
		return { onClick }
	},
	computed: {
		getClassBtn() {
			return `p-2 rounded text-center outline-none hover:${this.$props.bgHoverClass} ${this.$props.bgClass}`
		}
	}
})
</script>
