export interface AuthState {
    token: string | null
}

const state = (): AuthState => ({
	token: null
})

const mutations = {
	updateAuthToken (state: AuthState, token: string): void {
		state.token = token
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
