<template>
	<div>
		<div class="filter-section">
			<label for="filter">Show:</label>
			<select
				v-model="countSelected"
				class="number"
				@change="onSelectCount"
			>
				<option
					v-for="(number, index) in offsetList"
					:key="index"
					:value="number.value"
				>
					{{ number.label }}
				</option>
			</select>
			<select
				v-model="statusSelected"
				class="status"
				@change="onSelectStatus"
			>
				<option
					v-for="(status, index) in statusList"
					:key="index"
					:value="status.status"
				>
					{{ status.label }}
				</option>
			</select>
		</div>
		<div class="pagination-section">
			<div>{{ data.length }} items</div>
			<a
				class="prev-fist"
				@click="onPrevFirstPage"
			>
				<i class="icon-arrow_double_left" />
			</a>
			<a
				class="prev-one"
				@click="onPrevOnePage"
			>
				<i class="icon-arrow_left" />
			</a>
			<div class="page-status">
				<span class="current">{{ currentPage }}</span>
				<span class="total">of {{ Math.ceil(data.length/countSelected) }}</span>
			</div>
			<a
				class="next-one"
				@click="onNextOnePage"
			>
				<i class="icon-arrow_right" />
			</a>
			<a
				class="next-last"
				@click="onNextLastPage"
			>
				<i class="icon-arrow_double_right" />
			</a>
		</div>
		<div class="ph-container">
			<table class="ph-table">
				<tr>
					<th
						v-if="showCheckBox"
						class="checkbox"
					>
						<slot name="select-all">
							<input
								v-model="isSelectAll"
								type="checkbox"
								class="select-all"
								@change="onSelectAll"
							>
						</slot>
					</th>
					<th
						v-for="(header, index) in headers"
						:key="index"
						:class="`header-${header.key}`"
					>
						<slot :name="`header-${header.key}`">
							<p>
								{{ header.label }}
							</p>
						</slot>
					</th>
				</tr>
				<tr
					v-for="(item, index) in currentTable"
					:key="index"
					class=" cursor-pointer hover:bg-phGrey"
					@click="$emit('onRowClick', item)"
				>
					<td
						v-if="showCheckBox"
						class="checkbox"
					>
						<slot name="select-one">
							<input
								v-model="selectList[index]"
								type="checkbox"
								:value="item"
								class="select-one"
								@change="onSelectRow(index, item)"
							>
						</slot>
					</td>
					<td
						v-for="(header, idx) in headers"
						:key="idx"
						:class="`content-${header.key}`"
					>
						<slot
							:name="`content-${header.key}`"
							:item="item[header.key]"
						>
							<p>
								{{ item[header.key] }}
							</p>
						</slot>
					</td>
				</tr>
			</table>
		</div>
		<div class="pagination-section">
			<div>{{ data.length }} items</div>
			<a
				class="prev-fist"
				@click="onPrevFirstPage"
			>
				<i class="icon-arrow_double_left" />
			</a>
			<a
				class="prev-one"
				@click="onPrevOnePage"
			>
				<i class="icon-arrow_left" />
			</a>
			<div class="page-status">
				<span class="current">{{ currentPage }}</span>
				<span class="total">of {{ Math.ceil(data.length/countSelected) }}</span>
			</div>
			<a
				class="next-one"
				@click="onNextOnePage"
			>
				<i class="icon-arrow_right" />
			</a>
			<a
				class="next-last"
				@click="onNextLastPage"
			>
				<i class="icon-arrow_double_right" />
			</a>
		</div>
	</div>
</template>
<script>
import { defineComponent, ref, toRefs, watch } from 'vue'
export default defineComponent({
	props: {
		data: {
			type: Array,
			default: () => []
		},
		dataSelected: {
			type: Array,
			default: () => []
		},
		headers: {
			type: Array,
			default: () => []
		},
		showCheckBox: {
			type: Boolean,
			default: false
		},
		offsetList: {
			type: Array,
			default: () => []
		},
		statusList: {
			type: Array,
			default: () => []
		}
	},
	emits: ['update:dataSelected', 'onRowClick'],
	setup(props, { emit }) {
		const selectList = ref([])
		const isSelectAll = ref(false)
		const countSelected = ref(10)
		const statusSelected = ref(0)
		const currentTable = ref([])
		const selectDetail = ref([])
		const currentPage = ref(1)
		const tableList = ref([])
		const { data, dataSelected } = toRefs(props)
		const onSelectAll = () => {
			if (isSelectAll.value) {
				selectList.value = tableList.value.reduce((acc, _, index) => {
					acc[index] = true
					return acc
				}, [])
				tableList.value.forEach((item, index) => {
					dataSelected.value[index] = item
				})
				for(let i=1; i<=Math.ceil(tableList.value.length / countSelected.value); i++) {
					const tableArray = selectList.value
					selectDetail.value[i] = tableArray.slice((i - 1)*countSelected.value, (i * countSelected.value))
				}
			} else {
				selectList.value = tableList.value.reduce((acc, _, index) => {
					acc[index] = false
					return acc
				}, {})
				dataSelected.value.length = 0
				selectDetail.value.length = 0
			}
			emit('update:dataSelected', dataSelected.value)
		}
		const onSelectRow = (index, item) => {
			if (selectList.value[index]) {
				dataSelected.value[index] = item
				const dataList = dataSelected.value.filter(item => item)
				if (dataList.length === tableList.value.length) {
					isSelectAll.value = true
				}
			} else {
				isSelectAll.value = false
				delete dataSelected.value[index]
			}
			selectDetail.value[currentPage.value] = selectList.value
			emit('update:dataSelected', dataSelected.value)
		}
		const onSelectCount = () => {
			currentPage.value = 1
			currentTable.value = tableList.value.slice((currentPage.value - 1)*countSelected.value, countSelected.value)
		}
		const onSelectStatus = () => {
			currentPage.value = 1
		}
		const onPrevOnePage = () => {
			if (currentPage.value > 1) {
				currentPage.value--
				const index = (currentPage.value - 1) * 10
				currentTable.value = tableList.value.slice(index, countSelected.value + index)
				if(selectDetail.value[currentPage.value]) {
					selectList.value = selectDetail.value[currentPage.value]
				} else {
					selectList.value = []
				}
			}
		}
		const onNextOnePage = () => {
			if (currentPage.value < Math.ceil(tableList.value.length / countSelected.value)) {
				currentPage.value++
				const index = (currentPage.value - 1) * 10
				currentTable.value = tableList.value.slice(index, countSelected.value + index)
				if(selectDetail.value[currentPage.value]) {
					selectList.value = selectDetail.value[currentPage.value]
				} else {
					selectList.value = []
				}
			}
		}
		const onPrevFirstPage = () => {
			if (currentPage.value > 1) {
				currentPage.value = 1
				const index = (currentPage.value - 1) * 10
				currentTable.value = tableList.value.slice(index, countSelected.value + index)
				if(selectDetail.value[currentPage.value]) {
					selectList.value = selectDetail.value[currentPage.value]
				} else {
					selectList.value = []
				}
			}
		}
		const onNextLastPage = () => {
			if (currentPage.value < Math.ceil(tableList.value.length / countSelected.value)) {
				currentPage.value = Math.ceil(tableList.value.length / countSelected.value)
				const index = (currentPage.value - 1) * 10
				currentTable.value = tableList.value.slice(index, countSelected.value + index)
				if(selectDetail.value[currentPage.value]) {
					selectList.value = selectDetail.value[currentPage.value]
				} else {
					selectList.value = []
				}
			}
		}
		watch(data, (data) => {
			tableList.value = data.map((item, index) => {
				return {
					...item,
					id: index
				}
			})
			currentTable.value = tableList.value.slice((currentPage.value - 1)*countSelected.value, countSelected.value)
		})
		return {
			isSelectAll,
			selectList,
			countSelected,
			statusSelected,
			currentTable,
			currentPage,
			tableList,
			onSelectAll,
			onSelectRow,
			onSelectCount,
			onSelectStatus,
			onPrevOnePage,
			onNextOnePage,
			onPrevFirstPage,
			onNextLastPage
		}
	}
})
</script>
<style lang="scss" scoped>
.ph-container {
    padding: 1em 0;
    background-color: #000;
    border-radius: 8px;
}
.ph-table {
    width: 100%;
    .checkbox {
        width: 30px;
		input {
			width: 18px;
			height: 18px;
			cursor: pointer;
			-webkit-appearance: none;
			border: 1px solid #EBEBEB;
			background-color: #1C1C1C;
			&:checked {
				background-color: #BA3B1A;
				position: relative;
				&:after {
					content: '\2714';
					font-size: 14px;
					position: absolute;
					top: 0;
					left: 2px;
					color: #FFF;
				}
			}
		}
    }
    tr {
        border-bottom: 1px solid #FFF;
    }
    th {
        text-align: left;
        padding: 1em;
    }
    td {
        text-align: left;
        padding: 1em;
    }
}
.filter-section {
	padding: 0.5em 0;
	label {
		color: #878787;
		margin-right: 1em;
	}
	select {
		border: 5px solid #000;
		border-radius: 8px;
		background-color: #000;
		padding: 5px;
		margin: 0 0.2em;
	}
	.status {
		min-width: 150px;
	}
}
.pagination-section {
	display: flex;
	justify-content: flex-end;
	padding: 1em 0;
	align-items: center;
	div {
		margin: 0 0.5em;
		color: #878787;
	}
	a {
		cursor: pointer;
        margin: 0 0.2em;
		i {
			font-size: 2em;
			color: #979797;
		}
	}
	.page-status>.current {
		padding: 4px;
		margin-right: 5px;
		border: 1px solid #FFF;
	}
	.page-status>.total {
		margin-left: 5px;
	}
}
</style>
